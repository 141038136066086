(function($) {
  $.fn.extend({
    requireFields: function() {
      let $form = this;

      function auditFields() {
        let allFilled = true;
        $form.find('input[required]').each( (_index, element) => {
          allFilled = allFilled && !!element.value;
        });

        let disableSubmit = !allFilled;

        $form.find('input[type="submit"]').each( (_index, element) => {
          if (element.disabled != disableSubmit) {
            element.disabled = disableSubmit;
          }
        });
      }

      auditFields();

      $form.on('blur keyup', function() {
        auditFields();
      })
    },
    valid: function() {
      let $form = this;
      let allFilled = true;

      $form.find(':required').each( (_index, element) => {
        allFilled = allFilled && !!element.value;
      });

      return allFilled;
    },
    markRequiredFields: function() {
      let $form = this;

      $form.find(':required').each( (_index, element) => {
        if (!element.value) {
          $(element).addClass('is-invalid');

          var errorMessage = $(element).parent().find('.error-message');
          if(errorMessage.length == 0) {
            errorMessage = $("<span class='error-message'/>");
            $(element).after(errorMessage);
          }
          errorMessage.html('This field is required.');
        }
      });
    }
  });
})(jQuery);
