$(function () {
  $('.bpf-video-player').click(function () {
    const player = $(this).children('.player').get(0);
    const playerObject = $(player);
    const playButton = $(this).children('.play-button');
    if (player.paused) {
      playerObject.attr('played', true);
      playerObject.attr('controls', true);
      player.play();
      playButton.fadeOut();
      globalEventSend(playerObject.data('eventName'), {});
    } else {
      playerObject.attr('controls', false);
      player.pause();
      playButton.fadeIn();
    }
  });
})
