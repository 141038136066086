$(function() {

  let $modals = $('.full-screen-carousel.modal')

  $modals.each(function() {
    let $modal = $(this);
    let $fullScreenCarousel = $modal.find('.carousel');

    $modal.on('shown.bs.modal', function() {
      $(document).on('keydown.carousel', function(e) {
        let KEYCODE_ARROW_LEFT = 37;
        let KEYCODE_ARROW_RIGHT = 39;

        if (e.keyCode === KEYCODE_ARROW_LEFT) { $fullScreenCarousel.carousel('prev'); }
        if (e.keyCode === KEYCODE_ARROW_RIGHT) { $fullScreenCarousel.carousel('next'); }
      });
    });

    $modal.on('hidden.bs.modal', function() {
      $(document).off('keydown.carousel');
    });

    $fullScreenCarousel.find('.carousel-inner').on('click', function(e) {
      if ($(e.target).is('img')) {
        e.stopPropagation();
      } else {
        $modal.modal('hide');
      }
    });
  });

  let $carousel = $('#treePhotosCarousel .carousel');
  let $fullScreenCarousel = $('#treePhotosModal .carousel');
  let $modal = $('#treePhotosModal.modal');
  let currentSlide = 0;

  $carousel.on('slide.bs.carousel', function(e) {
    currentSlide = e.to;
  });

  $fullScreenCarousel.on('slide.bs.carousel', function(e) {
    currentSlide = e.to;
    $carousel.carousel(currentSlide);
  });

  $modal.on('show.bs.modal', function() {
    $fullScreenCarousel.carousel(currentSlide);
  });
});
