import 'jquery-mask-plugin';

$(function(){
  //all phone number inputs should have class .js-mask-phone-number
  let maskClass = '.js-mask-phone-number';
  let mask = '(000) 000-0000';

  $(maskClass).mask(mask);

  // Apply mask to new elements. This feature comes out of the
  // box with the plugin but doesn't work with jquery 3.
  // https://github.com/igorescobar/jQuery-Mask-Plugin/pull/484
  $(document).on('DOMNodeInserted', function(e) {
    let $phoneNumberElements = $(e.target).find(maskClass);
    if ($phoneNumberElements.length) {
      $phoneNumberElements.mask(mask);
    }
  });
});
