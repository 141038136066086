$(function(){
  $('#js-navbar-about').on('click', function(){
    $('#js-navbar-about-container').toggleClass('hidden');
  })

  $('#js-navbar-forests').on('click', function() {
    $('#js-navbar-forest-container').toggleClass('hidden');
  })

  $('body').on('click', function (e) {
      const aboutPicker = $('#js-navbar-about-container');
      const aboutPickerSelector = $('#js-navbar-about');

      const planningPicker = $('#js-navbar-planning-container');
      const planningPickerSelector = $('#js-navbar-planning');

      const forestPicker = $('#js-navbar-forest-container');
      const forestPickerSelector = $('#js-navbar-forests');

      if (aboutPicker.is(":visible") && !aboutPickerSelector.is(e.target) && aboutPickerSelector.has(e.target).length === 0) {
        aboutPicker.toggleClass('hidden');
      } else if (planningPicker.is(":visible") && !planningPickerSelector.is(e.target) && planningPickerSelector.has(e.target).length === 0) {
        planningPicker.toggleClass('hidden');
      } else if (forestPicker.is(":visible") && !forestPickerSelector.is(e.target) && forestPickerSelector.has(e.target).length === 0) {
        forestPicker.toggleClass('hidden');
      }
  });
})
