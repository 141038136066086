$(function() {
  let $fields = $(".js-credit-card-fields");

  if ($fields.length) {
    let $form = $fields.closest("form");
    let $submitButton = $form.find("input[type='submit']");
    let stripePublicKey = $("meta[name='stripe-public-key']").attr("content");

    let stripe = Stripe(stripePublicKey);
    let elements = stripe.elements();

    let classes = {
      base: 'form-control js-card-element',
      focus: 'focused-input',
      complete: 'complete-input js-complete'
    }

    let style = {
      base: {
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        color: '#436366',
      },
      invalid: {
        color: '#ff3b30',
      }
    };

    function setupElement(type, options) {
      let $DOMElement = $fields.find(".js-" + type);

      if ($DOMElement.length) {
        let element = elements.create(type, { classes: classes, style: style });

        element.on('change', function(event) {
          validationHandler(event);
          updateCompletionState(event);
        });

        if (options && options.focus) {
          element.on('ready', function() {
            element.focus();
          });
        }

        element.mount($DOMElement[0]);

        return element;
      }
    }

    function validationHandler(event) {
      let $errorContainer = $fields.find('.js-' + event.elementType + '-errors');

      if (event.error) {
        $errorContainer.text(event.error.message);
      } else {
        $errorContainer.text('');
      }
    }

    function updateCompletionState(event) {
      if (event.complete) {
        var allComplete = true;
        var $currentElement = $fields.find('.js-' + event.elementType);
        var $elements = $fields.find('.js-card-element');

        for (let element of $elements) {
          if (element != $currentElement[0]) {
            allComplete = allComplete && element.className.includes('js-complete');
          }
        }

        if (allComplete) {
          $fields.addClass("js-complete");
          $submitButton.attr('disabled', false)
        }
      } else {
        $fields.removeClass("js-complete");
        $submitButton.attr('disabled', true)
      }
    }

    function disableButton() {
      if ($submitButton.data("process-charge-with")) {
        $submitButton.data("restore-with", $submitButton.val())
        $submitButton.val($submitButton.data("process-charge-with"))
      }

      $submitButton.attr('disabled', true)
    }

    function restoreButton() {
      if ($submitButton.data("restore-with")) {
        $submitButton.val($submitButton.data("restore-with"))
      }

      $submitButton.attr('disabled', false)
    }

    let cardNumberElement = setupElement("cardNumber", { focus: true });
    setupElement("cardExpiry");
    setupElement("cardCvc");

    function submitForm($form) {
      if ($form.attr('data-remote') == 'true') {
        Rails.fire($form[0], 'submit');
      } else {
        $form.submit();
      }
    }

    $form.on('submit', function(event) {
      let $cardToken = $fields.find("[name='stripe_card_token']");

      if ($fields.is(":visible") && !$cardToken.val()) {
        event.preventDefault();
        event.stopPropagation();

        disableButton();

        stripe.createToken(cardNumberElement).then(function(result) {
          restoreButton();

          if (result.error && (result.error.type != "validation_error")) {
            $fields.find('#card-errors .js-general-errors').html(result.error.message);
          } else if (!result.error) {
            $cardToken.val(result.token.id);
            submitForm($form);
          }
        });
      }
    });
  }
});
