import { renderPointOfInterest, renderTreesOnMap, renderTrailsOnMap, renderSubsections } from "./map_utils";

export function setupTreesMap(accessToken, forestTrails, sectionBoundary, subsections, treesDetails, pointsOfInterest, mobileView = false) {
  mapboxgl.accessToken = accessToken;

  const mapContainer = 'map';
  const isInteractive = !mobileView;

  const map = new mapboxgl.Map({
    container: mapContainer,
    style: 'mapbox://styles/monicapoletti/ckl03ocua066517n3o1fbqqzn',
    interactive: isInteractive,
    attributionControl: false,
  });

  map.fitBounds(turf.bbox(sectionBoundary), {padding: 50, duration: 0});

  // Turn tree coordinates into GeoJson source for the map.
  let treePoints = {};
  treePoints.type = "FeatureCollection";
  treePoints.features = [];

  map.on('load', function() {
    // Add geolocate control to the map.
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        showUserHeading: true,
        trackUserLocation: true,
      })
    );

    // Add zoom controls to the map.
    if(!mobileView) {
      map.addControl(new mapboxgl.NavigationControl({
        showCompass: false,
      }));
    }

    map.addSource('section-polygon', {
      type: 'geojson',
      data: sectionBoundary
    });

    map.addLayer({
      id: 'section-polygon',
      type: 'fill',
      source: 'section-polygon',
      layout: {},
      paint: {
        'fill-color': '#005450',
        'fill-opacity': 0.2
      }
    });

    // Render tree markers
    renderTreesOnMap(treesDetails, map, mobileView)
    // Render POI markers if any.
    renderPointOfInterest(pointsOfInterest, map)
    // Render Trails
    renderTrailsOnMap(forestTrails, map, mobileView);
    // Render Subsections
    renderSubsections(subsections, map)
  });
}
