$(function(){

  var addNamedPersonRow = function(e) {
    var cloneRow = $('.named-persons:last').clone();
    var i = parseInt($('.named-persons:last :input')[0].id.match(/\d+/)[0]) + 1;

    var firstName = cloneRow.find('input')[0];
    firstName.id = 'spreading_authorization_named_persons_attributes_' + i + '_first_name';
    firstName.name = 'spreading_authorization[named_persons_attributes][' + i + '][first_name]';
    firstName.value = null;

    var lastName = cloneRow.find('input')[1];
    lastName.id = 'spreading_authorization_named_persons_attributes_' + i + '_middle_name';
    lastName.name = 'spreading_authorization[named_persons_attributes][' + i + '][middle_name]';
    lastName.value = null;

    var email = cloneRow.find('input')[2];
    email.id = 'spreading_authorization_named_persons_attributes_' + i + '_last_name';
    email.name = 'spreading_authorization[named_persons_attributes][' + i + '][last_name]';
    email.value = null;

    var phone = cloneRow.find('input')[3];
    phone.id = 'spreading_authorization_named_persons_attributes_' + i + '_date_of_birth';
    phone.name = 'spreading_authorization[named_persons_attributes][' + i + '][date_of_birth]';
    phone.value = null;

    cloneRow.appendTo("#named-people-container");
    $('.spreading-rights-helper-text').css('display', 'block');
  };

  $('.js-add-named-person').click(addNamedPersonRow);
})
