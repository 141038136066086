$(function() {
    $("body").on("click", ".js-favorite-icon, .js-unfavorite-icon", function(e) {
        const treeId = $(this).data("tree-id");

        $(this).hide();
        $(".js-favorite-loader.favorite_loader_tree_" + treeId).show();

    }).on("click", ".js-favorite-btn, .js-unfavorite-btn", function(e) {
        const treeId = $(this).data("tree-id");
        const $favoriteContainer = $(".js-favorite-btn-container[data-tree-id='" + treeId + "']");

        $favoriteContainer.find(".js-favorite-btn-icon").hide();
        $favoriteContainer.find(".js-favorite-loader").show();
    });
    
    $("#sort_by").change(function() {
        const form = $(this).closest("form");

        form.submit();
    });

    // if the request succeeds, programmatically hide the toast
    $('body').on('ajax:success', '.js-edit-favorite-description-form', function() {
        $(this).closest('.ecommerce-notification').toast('hide');
    });
});
