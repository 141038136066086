import ClipboardJS from 'clipboard/dist/clipboard'

$(() => {
  new ClipboardJS('.js-copy-to-clipboard');

  $(document).on('click', '.box-referral-option .js-copy-to-clipboard', function(e) {
    e.preventDefault();
    $('.js-copy-to-clipboard-label').val("Copied");
  })
    .on('mouseout', '.box-referral-option .js-copy-to-clipboard', function() {
      $('.js-copy-to-clipboard-label').val("Copy");
    });

});
