export function showSuccessMessage (message) {
  var modal = $("#successModal");
  modal.find(".js-message").html(message);
  modal.modal({});
  modal.find('.btn').focus();
}

export function showErrorMessage (message) {
  var modal = $("#errorModal");
  modal.find(".js-message").html(message);
  modal.modal({});
  modal.find('.btn').focus();
}

export function showSuccessButton(text) {
  var modal = $("#successModal");
  modal.find(".js-success-btn").text(text);
  modal.modal({});
}

export function showSuccessTitle (title) {
  var modal = $("#successModal");
  modal.find(".js-success-title").html(title);
  modal.modal({});
  modal.find('.btn').focus();
}