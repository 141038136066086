var ajaxRequest = null; // used to abort a current request if a new one is sent

$(function() {
  if($('.ecommerce .js-search-by-arbor-tag').length) {
    const $input = $('.js-search-by-arbor-tag-input');

    // clear input on page load
    $input.val('');

    // bind input
    bindSearchInput($input);
    bindClear($input);
  }
});

function bindSearchInput($input) {
  let typingTimer;
  const doneTypingInterval = 500 ;  // time in ms

  $input.on('input', function() {
    const query = $input.val().trim();

    clearTimeout(typingTimer);

    // clear results and don't fetch
    if(!query) {
      $('.js-search-results-container').html('');
      return;
    }

    typingTimer = setTimeout(function() {
      fetchResults(query);
    }, doneTypingInterval);
  });
}

function bindClear($input) {
  $('body').on('click', '.js-search-by-arbor-tag-clear', function() {
    $input.val('').trigger('input');
  })
}

function fetchResults(query) {
  const searchPath = $('.ecommerce .js-search-by-arbor-tag').data('search-path')
  const params = new URLSearchParams(window.location.search);

  params.append('query', query);

  // shows loading animation
  enableLoadingState();

  ajaxRequest = $.ajax({
    method: 'GET',
    url: searchPath,
    data: params.toString(),
    beforeSend: function(xhr) {
      if(ajaxRequest !== null) {
        ajaxRequest.abort();
      }
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    },
  }).always(function() {
    disableLoadingState();
  });
}

function enableLoadingState() {
  $('.js-search-by-arbor-tag-loading').removeClass('d-none');
}

function disableLoadingState() {
  $('.js-search-by-arbor-tag-loading').addClass('d-none');
}
