import { Slider } from "checkouts/slider";
import { setUpDatePicker } from "checkouts/custom_start_date";
import { submitCheckoutMetadataUpdate } from "checkouts/metadata_update";
import "shared/components/tooltip";

function showOverlay() {
  if ($('.js-overlay .checkout-overlay').length === 0) {
    $('.js-overlay').prepend("<div class='checkout-overlay'></div>");
  }
};

function hideOverlay() {
  $('.js-overlay .checkout-overlay').remove();
};

function initDesktopPaymentOptions() {
  $(document).on('click', ".js-payment-option-md", function(event) {
    event.preventDefault();

    let url = $(this).data("url");

    $(".js-payment-options-error").text("");

    showOverlay();
    $.ajax({
      method: 'PUT',
      url: url,
      params: {},
      success: function(data) {
        let $downPaymentContainer = $(".js-down-payment-md");

        if ($downPaymentContainer.length) {
          let slider = new Slider(document.querySelector(".custom-range"));
          slider.keepSyncedWith(document.querySelector(".js-down-payment-md input[type='number']"));
        }

        hideOverlay();
      },
      error: function(data) {
        hideOverlay();
        $(".js-payment-options-error").text("Something went wrong, please try again.");
      },
    });
  });
}

// Mobile version

function initMobileDownPayment() {
  let downPaymentTypingTimer;

  $(document).on("input", ".js-down-payment input", function(event) {
    let onDoneTyping = function() {
      let $downPaymentContainer = $(event.target).closest(".js-down-payment");
      let $paymentOptionContainer = $(event.target).closest(".js-payment-option");

      let downPaymentAmount = event.target.value;
      let startDate = $paymentOptionContainer.find('.js-start-date').data('start-date');
      let selectedPlanType = $paymentOptionContainer.data("plan-name");

      submitDownPaymentAmount($downPaymentContainer, downPaymentAmount, startDate, selectedPlanType, false);
    };

    clearTimeout(downPaymentTypingTimer);
    downPaymentTypingTimer = setTimeout(onDoneTyping, BPF.DONE_TYPING_INTERVAL);
  });
}

// Desktop version

function selectedPlanType() {
  return $(".js-payment-option-md.selected").data("plan-name");
}

function initDesktopDownPayment() {
  let downPaymentMdTypingTimer;

  $(document).on("input", ".js-down-payment-md input", function(event) {
    let onDoneTyping = function() {
      let $paymentOptionContainer = $(".js-payment-option-md.selected");
      let $downPaymentContainer = $(".js-down-payment-md");

      let downPaymentAmount = event.target.value || 0;
      let startDate = $('.js-start-date:visible').data('start-date');

      submitDownPaymentAmount($downPaymentContainer, downPaymentAmount, startDate, selectedPlanType, true);
    };

    clearTimeout(downPaymentMdTypingTimer);
    downPaymentMdTypingTimer = setTimeout(onDoneTyping, BPF.DONE_TYPING_INTERVAL);
  });
}

function submitDownPaymentAmount($downPaymentContainer, downPaymentAmount, startDate, selectedPlanType, isSummaryCard) {
  let cartUrlSlug = $downPaymentContainer.data('cart-url-slug');;
  let params = {
    down_payment: downPaymentAmount,
    start_date: startDate,
    option: selectedPlanType,
    page: 'checkout',
    card: isSummaryCard,
  };

  showOverlay();
  submitCheckoutMetadataUpdate(
    cartUrlSlug,
    params,
    function() {
      if (isSummaryCard) {
        let slider = new Slider(document.querySelector(".custom-range"));
        slider.keepSyncedWith(document.querySelector(".js-down-payment-md input[type='number']"));
      }

      $downPaymentContainer.find('input').focus();
      hideOverlay();
    },
    function() {
      if (isSummaryCard) {
        $(".js-payment-options-error").text("Something went wrong, please try again.");
      }
      hideOverlay();
    },
  );
}

$(function() {
  initMobileDownPayment();
  initDesktopPaymentOptions();
  initDesktopDownPayment();

  let $downPaymentContainer = $(".js-down-payment-md");

  if ($downPaymentContainer.length) {
    let slider = new Slider(document.querySelector(".custom-range"));
    slider.keepSyncedWith(document.querySelector(".js-down-payment-md input[type='number']"));
  }
});
