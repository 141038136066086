$(function(){
  $('#js-mobile-active-forest').on('click', function(){
    $('#js-mobile-nav-forests-menu').toggleClass('hidden');

    if ($('#js-mobile-nav-about-menu').is(":visible")) {
      $('#js-mobile-nav-about-menu').toggleClass('hidden');
    } else if ($('#js-mobile-nav-planning-menu').is(":visible")) {
      $('#js-mobile-nav-planning-menu').toggleClass('hidden');
    }
  })

  $('#js-mobile-active-about').on('click', function(){
    $('#js-mobile-nav-about-menu').toggleClass('hidden');

    if ($('#js-mobile-nav-forests-menu').is(":visible")) {
      $('#js-mobile-nav-forests-menu').toggleClass('hidden');
    } else if ($('#js-mobile-nav-planning-menu').is(":visible")) {
      $('#js-mobile-nav-planning-menu').toggleClass('hidden');
    }
  })

})
