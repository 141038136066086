import { removeLayer } from "./map_utils";

const forestMarkerHtml = '<i class="logo" aria-hidden="true"></i>'

export function setupAllForestsMap(accessToken, locationData, targetForestId, forestLocations) {
  const targetForestData = forestLocations.find(({ id }) => id == targetForestId);

  mapboxgl.accessToken = accessToken;

  const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/monicapoletti/ckl03ocua066517n3o1fbqqzn',
    center: [targetForestData.location.long, targetForestData.location.lat],
    zoom: 7,
    attributionControl: false,
  });

  map.addControl(new mapboxgl.NavigationControl());

  // Target Forest Marker
  const el = document.createElement('div');

  el.className = 'ecommerce js-target-forest-map-marker marker bounce';
  el.id = 'target_forest_map_marker_' + targetForestId;
  el.innerHTML = `<span class="logo" data-toggle="modal" data-target="#js-forest-modal-${targetForestId}">` + forestMarkerHtml + '</span>'

  let forestMarker = new mapboxgl.Marker(el)
    .setLngLat([targetForestData.location.long, targetForestData.location.lat])
    .addTo(map);

  for(const i in locationData) {
    var cityData = locationData[i]

    // City Marker
    const el = document.createElement('div');

    el.className = 'ecommerce js-city-map-marker city-selector';
    el.id = cityData.city_name.toLowerCase().split(' ').join('_');

    let marker = new mapboxgl.Marker(el)
      .setLngLat([cityData.long, cityData.lat])
      .addTo(map);
  };

  for (const i in forestLocations) {
    var forestData = forestLocations[i]

    if (forestData.id !== targetForestId) {
      // Other Forest Marker
      const el = document.createElement('div');

      el.className = 'ecommerce js-forest-map-marker marker';
      el.id = 'forest_map_marker_' + forestData.id;
      el.innerHTML = `<span class="logo" data-toggle="modal" data-target="#js-forest-modal-${forestData.id}">` + forestMarkerHtml + '</span>'

      let forestMarker = new mapboxgl.Marker(el)
        .setLngLat([forestData.location.long, forestData.location.lat])
        .addTo(map);
    }
  }

  return map;
}

export function getDistanceToForest(map, accessToken, locationData, targetCityName, targetForestId, forestLocations) {
  const targetForestData = forestLocations.find(({ id }) => id == targetForestId);
  const targetCityData = locationData.find(({ city_name }) => city_name === targetCityName);

  fetchRouteData(map, accessToken, targetCityData.long, targetCityData.lat, targetForestData.location.long, targetForestData.location.lat)

  return {
    to: {
      name: targetForestData.name,
      long: targetForestData.location.long,
      lat: targetForestData.location.lat,
    },
    from: {
      name: targetCityName,
      state: targetCityData.state_code,
      long: targetCityData.long,
      lat: targetCityData.lat,
    },
  }
}

async function fetchRouteData(map, accessToken, cityLong, cityLat, forestLong, forestLat) {
  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${cityLong},${cityLat};${forestLong},${forestLat}?access_token=${accessToken}&steps=false&geometries=geojson`
  const reqParams = {
    method: 'GET',
    headers: {
      "content-type": 'application/json',
    }
  }

  const query = await fetch(url, reqParams)
    .then(data => {return data.json()})
    .then(res => {addRouteToMap(map, res)});
}

function addRouteToMap(map, routeData) {
  const data = routeData.routes[0];
  const route = data.geometry.coordinates;
  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: route
    }
  };

  if (map.getLayer('route') !== undefined) {
    removeLayer(map, 'route');
  }

  map.addLayer({
    id: 'route',
    type: 'line',
    source: {
      type: 'geojson',
      data: geojson
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#EF9E55',
      'line-width': 6,
      'line-opacity': 1
    }
  });

  //pan and zoom the map to center the route in the middle
  if (route){
    map.fitBounds(turf.bbox(turf.lineString(route)), { padding: 100, duration: 200 });
  }

  pinRouteDuration(map, routeData);
  return map;
}

function pinRouteDuration(map, routeData) {
  var coordinates = routeData.routes[0].geometry.coordinates;
  var seconds = routeData.routes[0].duration;

  var hours = Math.floor((seconds / 60) / 60);
  var mins = roundTimeQuarterHour(Math.floor((seconds / 60) % 60));

  if (mins == 60) {
    hours += 1;
    mins = 0;
  }

  var centerCoord = coordinates[Math.round((coordinates.length - 1) / 2)];

  const popup = new mapboxgl.Popup({ closeOnClick: false, closeButton: false, offset: [20,20] })
    .setLngLat([centerCoord[0], centerCoord[1]])
    .setHTML(`<div class="js-duration-tooltip"><span>${hours}h ${mins}m drive</span></div>`)
    .addTo(map);
}

function roundTimeQuarterHour(time) {
  return Math.round(time / 15) * 15;
}
