$(function() {
  let $assistedCheckoutModal = $('#assisted-checkout-modal');

  if($assistedCheckoutModal.length) {
    let urlParams = new URLSearchParams(window.location.search)

    if(urlParams.get('open_assisted_checkout') === '1') {
      $assistedCheckoutModal.modal('show');
    }
  }
})