const favoriteMarkerHtml = ' <i class="fa fa-heart" aria-hidden="true"></i>'
const recommendedMarkerHtml = ' <i class="fa fa-star" aria-hidden="true"></i>'
import { externalAssetPath } from 'utilities/asset_path.js.erb';

/**
 * Render point of interest on the maps
 * @param {Object | null} pointsOfInterest Geojson with POI objects
 * @param {mapboxgl.Map} map
 */
export function renderPointOfInterest(pointsOfInterest, map) {
  if (!pointsOfInterest || !pointsOfInterest.length) return

  // Add markers POI to the map.
  for (const marker of pointsOfInterest) {
    // // Create a DOM element for each marker.
    const el = document.createElement('div');
    const width = marker.properties.poi_icon_size[0];
    const height = marker.properties.poi_icon_size[1];
    const iconUrl = externalAssetPath(`maps/points_of_interest/${marker.properties.poi_icon_filename}`);
    const activeIconUrl = externalAssetPath(`maps/points_of_interest/${marker.properties.poi_icon_filename_active}`);

    el.className = 'marker poi-marker';
    el.style.backgroundImage = `url(${iconUrl})`;
    el.style.backgroundRepeat = 'no-repeat';
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = 'contain';
    el.dataset.properties = JSON.stringify({
      icon: iconUrl,
      activeIcon: activeIconUrl,
      active: false
    });

    let popup = new mapboxgl.Popup({ offset: 10, className: 'map-card-popup poi', closeButton: false })
      .setHTML(marker.properties.poi_name)

    // Add markers to the map.
    new mapboxgl.Marker(el)
      .setLngLat(marker.geometry.coordinates)
      .setPopup(popup)
      .addTo(map);

    el.addEventListener('click', function(e) {
      const {
        icon,
        activeIcon,
        active
      } = JSON.parse(this.dataset.properties);
      resetPOIState()

      // This is negated since is the new value take in consideration
      if(active) {
        this.style.backgroundImage = `url(${iconUrl})`;
      } else {
        this.style.backgroundImage = `url(${activeIconUrl})`;
      }

      this.dataset.properties = JSON.stringify({
        icon,
        activeIcon,
        active: !active
      });
    });
  }

  // First render zoom level
  checkPOIVisibility(map.getZoom())

  // Hide poi at certain zoom level
  map.on("zoom", function() {
    checkPOIVisibility(map.getZoom())
  })

  // Reset Point of intereset only if clicking outside a poi-marker
  map.on('click', function(e) {
    const elementClasses = $(e.originalEvent.target).attr('class')
    if (elementClasses && elementClasses.split(/\s+/).includes('poi-marker')) return;

    resetPOIState()
  })

  BPF.Ecommerce.mapLegendListeners(map);
}

/**
 * Query for all the poi markers & reset them to their default icon
 */
export function resetPOIState() {
  $('.poi-marker').each((i, el) => {
    const {
      icon,
    } = $(el).data('properties');

    el.style.backgroundImage = `url(${icon})`;

    el.dataset.properties = JSON.stringify({
      ...$(el).data('properties'),
      active: false
    });
  })
}

export function removeLayer(map, layerName) {
  map.removeLayer(layerName);
  map.removeSource(layerName);

  return map
}

export function renderTreesOnMap(treesDetails, map, mobileView) {
  if (!treesDetails) return;

  const { trees, favorites, recommendations, collectionTreeIds } = treesDetails;
  let treeMarkers = [];

  for(const treeId in trees) {
    // Create a DOM element for the marker.
    const el = document.createElement('div');
    el.id = 'tree_marker_' + treeId;

    el.className = 'ecommerce js-tree-marker';

    // if the tree is part of a collection, apply custom styling
    if(collectionTreeIds.includes(parseInt(treeId))) {
      el.classList.add('collection')
      el.innerHTML = `<i class='far fa-check-circle js-collection-icon'></i>${trees[treeId][2]}`;
    } else {
      el.innerHTML = trees[treeId][2];
    }

    if (favorites.includes(parseInt(treeId))) {
      // If tree is in a customer's favorites, apply additional UI treatment.
      addFavoriteStyle(el)
    } else if (recommendations.includes(parseInt(treeId))) {
      addRecommendedStyle(el);
    }

    let popup = new mapboxgl.Popup({ offset: 25, className: 'map-card-popup', closeButton: false })
      .setHTML('<div id="map_card_popup_contents">')

    // Add marker to the map.
    let marker = new mapboxgl.Marker(el)
      .setLngLat([trees[treeId][0], trees[treeId][1]])
      .setPopup(popup)
      .addTo(map);

    // Keep track of the markers in an array since Mapbox doesn't save any
    // references to the markers once they're added.
    treeMarkers.push({
      id: ('tree_marker_' + treeId),
      tree_id: treeId,
      marker
    });
  };

  //Add separate listeners for clicks/mouse movements on desktop and mobile.
  if (mobileView) {
    // Get a list of all tree cards to iterate through as a user scrolls.
    const treeCards = [];
    $('.ecommerce').find('.map-tree-card').each(function(i, obj) {
      treeCards.push("#" + obj['id']);
    });

    // Highlight the tree corresponding to the first card being displayed.
    map.once('idle', (e) => {
      BPF.Ecommerce.highlightMobileTreeCardsOnSwipe(map, treeCards, treeMarkers)
    });

    BPF.Ecommerce.treesMobileMapListeners(map, treeMarkers, treeCards);
  } else {
    BPF.Ecommerce.treesDesktopMapListeners(map);
  }
}

export function renderTrailsOnMap(trailsDetails, map, mobileView) {
  for(var i = 0; i < trailsDetails.length; i++) {
    map.addSource(`trails-${trailsDetails[i]['id']}`, {
      'type': 'geojson',
      'data': trailsDetails[i]['boundary_polygon']
    });

    var styles = {};
    var trailColor = '#493E2C';

    switch (trailsDetails[i]['trail_class']) {
      case 1:
        styles = {
          'line-color': trailColor,
          'line-width': mobileView ? 1.5 : 2.5,
        }

        break;
      case 2:
        styles = {
          'line-color': trailColor,
          'line-width': mobileView ? 1 : 2,
          'line-dasharray': [2, 3]
        }

        break;
      case 3:
        styles = {
          'line-color': trailColor,
          'line-width': mobileView ? 1 : 1.5,
          'line-dasharray': [1, 2],
        }

        break;
    }

    map.addLayer({
      'id': `trails-${trailsDetails[i]['id']}`,
      'type': 'line',
      'source': `trails-${trailsDetails[i]['id']}`,
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': styles,
    });
  }
}

function loadSubsectionsPatternImage(map) {
  const imagePath = externalAssetPath('maps/subsection_fill_pattern.png');

  // Load the image to use as the pattern
  map.loadImage(imagePath, (err, image) => {
    // Throw an error if something goes wrong.
    if (err) throw err

    if(!map.hasImage('subsection-pattern')) {
      // Add the image to the map style.
      map.addImage('subsection-pattern', image);
    }
  });
}

export function renderSubsections(subsections, map) {
  loadSubsectionsPatternImage(map)

  for(let i = 0; i < subsections.length; i++) {
    const subsectionId = subsections[i].id;

    map.addSource(`subsection-polygon-${subsectionId}`, {
      type: 'geojson',
      data: subsections[i].boundary_polygon,
    })

    map.addLayer({
      id: `subsection-polygon-${subsectionId}`,
      type: 'fill',
      source: `subsection-polygon-${subsectionId}`,
      layout: {},
      paint: {
        'fill-pattern': 'subsection-pattern',
        'fill-opacity': 1
      }
    });

    map.addLayer({
      id: `subsection-polygon-border-${subsectionId}`,
      type: 'line',
      source: `subsection-polygon-${subsectionId}`,
      paint: {
        'line-color': '#818181',
        'line-width': 2,
      }
    });

    // open modal when clicking on a featured subsection
    map.on('click', `subsection-polygon-${subsectionId}`, event => {
      const target = event.originalEvent.target;

      // .mapboxgl-marker class is automatically added to every marker
      // if the click was originated in a marker, don't show the modal
      if(!target.className.includes('mapboxgl-marker')) {
        $(`#featured-location-modal-${subsectionId}`).modal();
      }
    });
  }
}

export function addFavoriteStyle(markerElement) {
  if (markerElement != null) {
    markerElement.classList.add('favorite');
    markerElement.innerHTML += favoriteMarkerHtml;
  }
};

export function removeFavoriteStyle(markerElement) {
  if (markerElement != null) {
    markerElement.classList.remove('favorite');
    markerElement.innerHTML = markerElement.innerHTML.replace(favoriteMarkerHtml, '');
  }
};

export const addRecommendedStyle = (markerElement) => {
  if (markerElement != null) {
    markerElement.classList.add('recommendation');
    markerElement.innerHTML += recommendedMarkerHtml;
  }
};

function checkPOIVisibility(zoomLevel) {
  if (zoomLevel > 15) {
    $('.poi-marker').show()
  } else {
    $('.poi-marker').hide()
  };
}
