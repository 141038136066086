$(function() {
    const $carousels = $('.ecommerce .js-about-this-forest-carousel');
    if($carousels.length) {
        $carousels.each(function() {
           $(this).on('slide.bs.carousel', function(event) {
               const slideIndex = event.to;
               const $thumbsContainer = $('.carousel-indicators-thumbs');
               const $thumb = $thumbsContainer.find(".img-carousel-indicator[data-slide-to='" + slideIndex + "']");
               const thumbLeftPosition = $thumb.offset().left
               const thumbLeftPadding = ($thumbsContainer.width() - $thumb.width()) / 2
               const scrollPosition = $thumbsContainer.scrollLeft() + thumbLeftPosition - thumbLeftPadding

               $thumbsContainer.animate({
                   scrollLeft: scrollPosition
               }, 500);
           });
        });
    }
});