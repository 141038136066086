const EMAIL_OPTION_CLASS = 'js-referral-option-email';
const COPY_OPTION_CLASS = 'js-referral-option-copy';
const FACEBOOK_OPTION_CLASS = 'js-referral-option-facebook';

export function openPopUp(link) {
  window.open(link, '_blank', 'width=550,height=745');
}

function updateOptionLabel($option, newLabel) {
  $option.find('.referral-option-label').text(newLabel);
}

function referralOptionIsFacebook($option) {
  return $option.hasClass(FACEBOOK_OPTION_CLASS);
}

function referralOptionIsCopy($option) {
  return $option.hasClass(COPY_OPTION_CLASS);
}

function selectReferralOption($option) {
  let $activeOption = $('.round-referral-option.active');

  // If the active option no longer is 'Copy' then restore label
  if (referralOptionIsCopy($activeOption)) {
    updateOptionLabel($activeOption, 'Copy Link');
  }

  // Update active option
  $activeOption.removeClass('active');
  $option.addClass('active');

  if (referralOptionIsFacebook($option)) {
    let postToFacebookLink = $option.data('postToFacebookLink');
    openPopUp(postToFacebookLink);
  } else if (referralOptionIsCopy($option)) {
    updateOptionLabel($option, 'Copied!');
  }
}

$(() => {
  $(document).on('click', '.round-referral-option', (e) => {
    let $selectedOption = $(e.currentTarget);

    if (!$selectedOption.hasClass('active')) {
      selectReferralOption($selectedOption);
    }
  });

  $(document).on('click', '.referral-form-content form', () => {
    let emailOptionClass = `.${EMAIL_OPTION_CLASS}`
    selectReferralOption($(emailOptionClass));
  });
});
