$(function(){
  $('.ecommerce .js-nav-toggle').on('mouseenter', function(){

    const $toggle = $(this);
    const $container = $toggle.find($('.js-nav-toggle-container'));

    const isHidden = $container.hasClass('d-none');

    if(isHidden) {
      // hide any that might already be open
      $('.js-nav-toggle-container').addClass('d-none');

      // show active
      $container.removeClass('d-none');

      // bind click outside element
      $(document).on('click', 'body', function(e) {
        if(!$(e.target).is($toggle) && !$(e.target).closest($container).length) {
          hideContainer();
        }
      });
    } else {
      hideContainer();
    }
  });
});

function hideContainer() {
  const $container = $('.ecommerce .js-nav-toggle-container');

  // hide
  $container.addClass('d-none');

  // unbind click outside element
  $(document).off('click', 'body');
}
