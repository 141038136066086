/*
  Handler for password input that shows/hides the password
  Usage:
  <div class="input-group input-group-overlay-append">
    <input type="password">
    <div class="input-group-append">
      <button name="button" type="button" class="btn text-dark show-password">Show</button>
    </div>
  </div>
*/

(function($) {
  $.fn.extend({
    toggleShowPassword: function() {
      this.each(function() {
        let $toggleButton = $(this);
        let $passwordInput = $toggleButton.closest('.input-group').find('input');

        function updateToggleButtonVisibility($input) {
          if ($input.val().length) {
            $toggleButton.show();
          } else {
            $toggleButton.hide();
          }
        };

        updateToggleButtonVisibility($passwordInput);

        $passwordInput.on('keyup change', function(e) {
          updateToggleButtonVisibility($(e.target));
        });

        $toggleButton.on('click', function() {
          if ($passwordInput.attr('type') === 'password') {
            $passwordInput.attr('type', 'text');
            this.innerHTML = 'Hide';
          } else {
            $passwordInput.attr('type', 'password');
            this.innerHTML = 'Show';
          }
        });
      });
    }
  });
})(jQuery);


// Actually call the above function on .show-password elements
$(function() {
  $('.show-password').toggleShowPassword();
});
