$(function () {
  if (!$(".all-forests-map-container").length || process.env.RAILS_ENV === "test") {
    return;
  }

  let nearestCities = JSON.parse(gon.nearest_cities);
  let loopIndex = -1;
  const accessToken = process.env.MAPBOX_ACCESS_TOKEN;
  const forestLocations = JSON.parse(gon.forest_locations);
  const forestId = gon.forest.id;
  const forestData = forestLocations.find(({ id }) => id === forestId);

  const map = BPF.Ecommerce.setupAllForestsMap(
    process.env.MAPBOX_ACCESS_TOKEN,
    nearestCities,
    forestId,
    forestLocations,
  );

  async function fetchRouteDistances(forest, city) {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${city.long},${city.lat};${forest.location.long},${forest.location.lat}?access_token=${accessToken}&steps=false&geometries=geojson`;
    const reqParams = { method: 'GET', headers: { "content-type": 'application/json' } };
    await fetch(url, reqParams)
      .then(data => {return data.json()})
      .then(res => {updateNearestCityData(res, forest, city)});
  }

  function updateNearestCityData(res, forest, target_city) {
    let thisCity = nearestCities.find(city => city.city_name === target_city.city_name);
    thisCity.distance = res.routes[0].distance;
    nearestCities = nearestCities.sort((a, b) => (a.distance > b.distance) ? 1 : ((a.distance < b.distance) ? -1 : 0));
  }

  nearestCities.forEach(function(city) {
    fetchRouteDistances(forestData, city);
  });

  function showNearestCity() {
    if ($(this).data("action") === "next") {
      ++loopIndex;
      if (loopIndex > nearestCities.length - 1) {
        loopIndex = 0;
      }
    } else if ($(this).data("action") === "prev") {
      --loopIndex;
      if (loopIndex < 0) {
        loopIndex = nearestCities.length - 1;
      }
    }
    const currentCity = nearestCities[loopIndex];
    // Hide tooltip if open
    $("#nearby-city-tooltip").hide();
    // Display both arrows
    $(".navigation-arrows").removeClass("d-none");
    // Update name on the selector
    $("#nearby-city-name").text(currentCity.city_name);
    // Remove any time map tooltip
    $(".js-duration-tooltip").remove();

    // Render directions on the map
    const directions = BPF.Ecommerce.getDistanceToForest(
      map,
      accessToken,
      nearestCities,
      currentCity.city_name,
      forestId,
      forestLocations,
    );
    const url = `https://www.google.com/maps/dir/?api=1&origin=${directions.from.name},${directions.from.state}&destination=Better Place Forests ${directions.to.name}&travelmode=driving`;

    $(".js-google-maps").removeClass("d-none");
    $("#js-google-maps-modal a").attr("href", url);
  }

  $(".nearby-city-selector .navigation-arrows").on("click", showNearestCity);
});
