import { validateTermsAcceptance } from './terms_checkbox_toggle';
import { showCheckoutButton } from './payment_button'
import { validateBillingAddress } from "./billing_address";
import * as Sentry from "@sentry/browser";

var braintreeClient = require('braintree-web/client');
var usBankAccount = require('braintree-web/us-bank-account');

$(document).ready(function () {
  $('#ach_ownership_type').change(function () {
    if ($('.ach-form').length == 0) {
      return;
    }

    if ($(this).val() === 'business') {
      $('.js-business-ownership-fields').show();
    } else {
      $('.js-business-ownership-fields').hide();
    }
  });

  $('.js-checkout-button').on('click.enabled', function (event) {
    if ($('.ach-payment-type').length == 0) {
      return;
    }

    event.preventDefault();

    let termsAccepted = validateTermsAcceptance();
    let mailingAddressValid = validateBillingAddress();
    if ($('.ach-form').valid() && termsAccepted && mailingAddressValid) {
      $(this).prop("disabled", true);
      $('.js-spinner').css("display", "inline-block");

      const noncePromise = fetchNonceFromBraintree(this);
    } else {
      $('#ach-payment-form').markRequiredFields();
      $('.card-errors').html(
        'Please fill out all required fields'
      );
    }
  });

  // Only display the button once the above event
  // handler is set up
  showCheckoutButton();
  $('#ach_ownership_type').change()

  $('.js-ach-billing-same-as-mailing').change(function () {
    if ($(this).is(':checked')) {
      $('#billing_address_line_1').val($('#address_address_line_1').val());
      $('#billing_address_line_2').val($('#address_address_line_2').val());
      $('#billing_state').val($('#address_state').val());
      $('#billing_city').val($('#address_city').val());
      $('#billing_zip').val($('#address_zip').val());

      $('.js-billing-address-field').prop("disabled", true);
    } else {
      $('#billing_address_line_1').val('');
      $('#billing_address_line_2').val('');
      $('#billing_state').val('');
      $('#billing_city').val('');
      $('#billing_zip').val('');

      $('.js-billing-address-field').prop("disabled", false);
    }
  });
});

async function fetchNonceFromBraintree(checkoutButton) {
  await braintreeClient.create({
    authorization: process.env.BRAINTREE_SDK_TOKEN
  }).then(function (clientInstance) {
    return usBankAccount.create({
      client: clientInstance
    })
  }).then(function (usBankAccountInstance) {
    usBankAccountInstance.tokenize({
      bankDetails: bankDetails(), mandateText: $('#ach-mandate-text').text(),
    }, function (tokenizeError, tokenizeEtokenizedPayload) {
      if (tokenizeError) {
        $(checkoutButton).prop("disabled", false);
        $('.card-errors').html(
          'Unable to verify bank information. Please check your details and try again.'
        );

        console.error('There was an error tokenizing the bank details.');
        Sentry.captureException(tokenizeError);
        return;
      }
      if (tokenizeEtokenizedPayload.nonce.length > 0) {
        $('#payment_nonce').val(tokenizeEtokenizedPayload.nonce);
        $('.ach-form').submit();
      }
      return tokenizeEtokenizedPayload.nonce;
    })
  });
}

function bankDetails() {
  const details = {
    accountNumber: $('#account_number').val(),
    routingNumber: $('#routing_number').val(),
    accountType: $('#account_type').val(),
    ownershipType: $('#ach_ownership_type').val(),
    billingAddress: {
      streetAddress: $('#billing_address_line_1').val(),
      extendedAddress: $('#billing_address_line_2').val(),
      locality: $('#billing_city').val(),
      region: $('#billing_state').val(),
      postalCode: $('#billing_zip').val()
    }
  };

  if (details.ownershipType === 'personal') {
    details.firstName = $('#customer_first_name').val();
    details.lastName = $('#customer_last_name').val();
  } else {
    details.businessName = $('#business_name').val();
  }

  return details;
}
