import { isValidEmail, isValidPhoneNumber } from "../utilities/validation";

export function validateCustomerInformation() {
  let allValid = true;

  function showErrorMessage(el, msg) {
    el.addClass('is-invalid');
    var errorMessage = el.parent().find('.error-message');
    if(errorMessage.length == 0) {
      errorMessage = $("<span class='error-message'/>");
      el.after(errorMessage);
    }
    errorMessage.html(msg);
  }

  function removeErrorMessage(el) {
    el.removeClass('is-invalid').parent().find('.error-message').remove();
  }

  $('.js-customer-info-required').each(function(event){
    if ($.trim($(this).val()).length == 0){
      showErrorMessage($(this), 'This field is required.')
      allValid = false;
    } else {
      removeErrorMessage($(this))
    }
  });

  $('.js-validate-email').each(function(event) {
    let $isValid = isValidEmail($.trim($(this).val()))
    if ($isValid) {
      removeErrorMessage($(this))
    } else {
      showErrorMessage($(this), 'Please enter a valid email.')
    }
    allValid = $isValid
  });

  $('.js-validate-phone-number').each(function(event) {
    let $isValid = isValidPhoneNumber($.trim($(this).val()))
    if ($isValid) {
      removeErrorMessage($(this))
    } else {
      showErrorMessage($(this), 'Please enter a valid phone number.')
    }
    allValid = $isValid
  });

  return allValid;
}
