import stickybits from 'stickybits';

$(function() {
  stickybits(".js-sticky", { useStickyClasses: true });


  // Mobile nav: Prevent double scroll when collapsed menu is visible
  const $nav = $('.ecommerce-mobile-nav-v2 .navbar');
  if($nav.length) {
    $nav.on('show.bs.collapse', function () {
      $('body').css('overflow-y', 'hidden');
    });
    $nav.on('hide.bs.collapse', function () {
      $('body').css('overflow-y', 'scroll');
    });
  }

  // Desktop nav: On hover of dropdown, show dropdown menu. Adds a debouncer to prevent flickering.
  let navDebouncer;
  const $megamenuDropdown = $('.ecommerce-nav-v2 .megamenu-li');
  if($megamenuDropdown.length) {
    $megamenuDropdown.hover(function() {
      clearTimeout(navDebouncer);
      $(this).find('.dropdown-toggle').addClass('show').attr('aria-expanded', 'true');
      $(this).find('.megamenu').addClass('show');

      // remove the show class from all the other .megamenu-li elements
      $megamenuDropdown.not(this).find('.dropdown-toggle').removeClass('show').attr('aria-expanded', 'false');
      $megamenuDropdown.not(this).find('.megamenu').removeClass('show');
    }, function() {
      const $this = $(this);
      navDebouncer = setTimeout(function() {
        $this.find('.dropdown-toggle').removeClass('show').attr('aria-expanded', 'false');
        $this.find('.megamenu').removeClass('show');
      }, 500);
    });

    // if they hover on the shown .megamenu, keep it shown
    $('.ecommerce-nav-v2 .megamenu').hover(function() {
      clearTimeout(navDebouncer);
    }, function() {
      $(this).removeClass('show');
    });
  }
});
