$(document).on('change', '.photoUploadingMessage :file', function() {
  uploadPhotos($(this));
});

function uploadPhotos(input) {
  // Check to see if the user navigated to the page using the back or
  // forward button, and skip reuploading files if so.
  // See https://www.w3.org/TR/navigation-timing/#performancenavigation
  //
  // For future, future reference, non-ratified new syntax that is still in
  // draft mode and not yet compatible with Safari is:
  //   `window.performance.getEntriesByType("navigation")[0].type != "back_forward"`
  if (window.performance && window.performance.navigation.type != 2) {
    let numFiles = input.get(0).files ? input.get(0).files.length : 1;
    let form = input.parents('form')
    form.submit();
    form.find('.photoUploadingMessage').html('Uploading ' + numFiles + ' photos');
  }
}