import 'bootstrap/js/dist/carousel';

// image modal functionality
function openModal(modalId) {
  if (modalId === 'unknown') {
    return;
  }

  $('#' + modalId).modal('show');
  window.modalId = '#' + modalId;
}

function closeModal(modalId) {
  const carousel = getCarousel(modalId);
  $('#' + modalId).modal('hide');

  // unbind event from carousel
  carousel.off('slid.bs.carousel');
}

var slideIndex = 1;

function currentSlide(n) {
  showSlides(slideIndex = n);
}

function showSlides(n) {
  var i;
  var slides = $(window.modalId + " .slides");
  if (n > slides.length) {slideIndex = 1}
  if (n < 1) {slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  if(slides[slideIndex-1]) {
    slides[slideIndex-1].style.display = "block";
  }
}

function setPhotoNumberIndicatorEvent(modalId) {
  const $carousel = getCarousel(modalId);
  const indicator = $('#' + modalId).find('.js-current-photo-number');
  $carousel.on('slid.bs.carousel', function (event) {
    indicator.text((event.to + 1).toString());
  })
}

function setPhotoTagEvent(modalId) {
  const $carousel = getCarousel(modalId);
  const $photoTagContainer = $('#' + modalId).find('.js-photo-tag');
  const photoTags = $photoTagContainer.data('photo-tags');

  // if we don't have any tags, just early-exit
  if(!photoTags || photoTags.length === 0) {
    return;
  }

  $carousel.on('slid.bs.carousel', function (event) {
    const tag = photoTags[event.to];
    if(tag !== null) {
      $photoTagContainer.show();
      $photoTagContainer.text(photoTags[event.to]);
    } else {
      $photoTagContainer.hide();
    }
  })
}


function setSlide(modalId, imageIndex) {
  const carousel = getCarousel(modalId);
  carousel.carousel(imageIndex);
}

function getCarousel(modalId) {
  return $(".modal#" + modalId).find(".carousel");
}

function modalAfterLoad(modalId, imageIndex) {
  getCarousel(modalId).each(function() {
    BPF.Ecommerce.progressiveLoadPhoto($(this));
  })

  openModal(modalId);

  currentSlide(1);

  if(!isNaN(imageIndex) && imageIndex >= 0) {
    setSlide(modalId, imageIndex);
  }

  setPhotoNumberIndicatorEvent(modalId);
  setPhotoTagEvent(modalId);
}


$(function() {
  let interval;
  showSlides(slideIndex);

  $('body').on('click', '.js-ecommerce-open-modal', function(e) {
    e.preventDefault();

    const modalId = $(this).data('modal-id')
    const showTags = $(this).data('show-tags')
    const remote = $(this).data('remote')
    const remote_path = $(this).data('remote-path')
    const imageIndex = parseInt($(this).data('slide-to'));

    if(!remote) {
      modalAfterLoad(modalId, imageIndex)
    } else {
      $.get(
        remote_path,
        { modal_id: modalId, show_tags: showTags }
      ).then(function() {
        modalAfterLoad(modalId, imageIndex)
      })
    }
  });

  $('body').on('click', '.js-ecommerce-close-modal', function() {
    let modalId = $(this).data("modal-id");
    if(modalId == null || modalId == "") {
      const modal = $(this).closest(".modal");
      if(modal.length === 0) {
        return false;
      }

      modalId = modal.attr('id').toString();
    }
    closeModal(modalId);
  });
});
