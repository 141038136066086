import { validateTermsAcceptance } from 'checkouts/terms_checkbox_toggle'
import { validateCustomerInformation } from 'checkouts/customer_information'
import { showCheckoutButton } from 'checkouts/payment_button'
import { validateBillingAddress } from 'checkouts/billing_address';

$(function() {
  let $form = $('#non-cc-payment-form');
  if ($form.length > 0) {
    let $button = $('.js-checkout-button');

    $button.on('click.enabled', function(e) {
      e.preventDefault();

      if (validateTermsAcceptance() && validateCustomerInformation() && validateBillingAddress()) {
        $button.html('Processing...');
        $button.off('click.enabled')

        $form.trigger('submit');
      }
    });

    // Only display the button once the above event
    // handler is set up
    showCheckoutButton();
  }
});
