import { renderTrailsOnMap } from "./map_utils";

export function setupTreeLocationMap(access_token,
                                     forestTrails,
                                     forestSections,
                                     treeForestSectionId,
                                     treeCoordinates,
                                     mapContainerId = 'map') {
  const map = initializeMap(access_token, forestSections, mapContainerId);

  map.on('load', function() {
    drawForestSections(forestSections, treeForestSectionId, map);
    drawTrails(forestTrails, map);
    drawTreeMarker(treeCoordinates, map);
    drawForestSectionLabel(forestSections, treeForestSectionId, map);
  });
}

function initializeMap(access_token, forestSections, mapContainerId) {
  mapboxgl.accessToken = access_token;

  const map = new mapboxgl.Map({
    container: mapContainerId,
    style: 'mapbox://styles/monicapoletti/ckl03ocua066517n3o1fbqqzn',
    interactive: false,
    attributionControl: false,
  });

  // let's position the zooming of the map using the boundaries of all sections
  let allSectionsCoordinates = [];
  Object.entries(forestSections).forEach((forestSection) => {
    let values = JSON.parse(forestSection[1]['boundaryPolygon']);

    // skip if the section doesn't have a boundary polygon
    if(values == null) {
      return;
    }

    values = values.features[0].geometry.coordinates[0][0];
    allSectionsCoordinates = allSectionsCoordinates.concat(values);
  })

  map.fitBounds(turf.bbox(turf.lineString(allSectionsCoordinates)), { padding: 15, duration: 0 });

  return map;
}

function drawTrails(forestTrails, map) {
  renderTrailsOnMap(forestTrails, map);
}

function drawForestSections(forestSections, treeForestSectionId, map) {
  for(const forestSection in forestSections) {
    const sourceData = JSON.parse(forestSections[forestSection]['boundaryPolygon']);
    const sectionColor = forestSection === treeForestSectionId ? '#A15E22' : '#94C5B8';

    map.addSource(('section-polygon-' + forestSection), {
      'type': 'geojson',
      'data': sourceData,
      'promoteId': 'Name'
    });

    map.addLayer({
      'id': ('section-polygon-' + forestSection),
      'type': 'fill',
      'source': ('section-polygon-' + forestSection),
      'layout': {},
      'paint': {
        'fill-color': sectionColor
      }
    });
  };
}

// Create a DOM element for the section name label. This will be position at the top left corner using CSS.
function drawForestSectionLabel(forestSections, treeForestSectionId, map) {
  // If we don't have the mkt name, dont draw the label
  if(!forestSections[treeForestSectionId] || !forestSections[treeForestSectionId]['marketingName']) {
    return;
  }

  const labelEl = document.createElement('div');
  labelEl.className = 'ecommerce js-tree-details-section-label';
  labelEl.id = 'section_label_' + treeForestSectionId;
  labelEl.innerHTML = '<span>' + forestSections[treeForestSectionId]['marketingName']+ '</span>'

  // even though we are setting its position here, we override it with css in map.scss
  new mapboxgl.Marker(labelEl)
      .setLngLat(map.getBounds().getNorthWest())
      .addTo(map);
}

// Create a DOM element for the tree marker
function drawTreeMarker(treeCoordinates, map) {
  // If we don't have the tree's coordinates, don't draw the tree marker
  if(treeCoordinates.includes(null)) {
    return;
  }

  const bpfLogoIcon = document.createElement('div');
  bpfLogoIcon.className = 'ecommerce';
  bpfLogoIcon.className = 'js-tree-details-tree-marker';

  new mapboxgl.Marker(bpfLogoIcon)
      .setLngLat(treeCoordinates)
      .addTo(map);
}
