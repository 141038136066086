import MapboxGeocoder from 'mapbox-gl-geocoder';
import { renderTrailsOnMap} from "./map_utils";

export const setupForestMap = (accessToken, forestAddress, forestTrails, forestSectionDetails) => {
  mapboxgl.accessToken = accessToken;

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
    limit: 1,
    marker: false,
    flyTo: false,
  });

  geocoder.onAdd('#forest-geo-map');
  geocoder.query(forestAddress);
  geocoder.on('result', result => {
    const map = new mapboxgl.Map({
      container: 'forest-map',
      style: 'mapbox://styles/monicapoletti/ckl03ocua066517n3o1fbqqzn',
      interactive: false,
      center: result.result.center,
      zoom: 11,
      attributionControl: false,
    });
    new mapboxgl.Marker().setLngLat(result.result.center).addTo(map);

    paintForestTrails(map, forestTrails);
  }).on('error', () => {
    const map = new mapboxgl.Map({
      container: 'forest-map',
      style: 'mapbox://styles/monicapoletti/ckl03ocua066517n3o1fbqqzn',
      interactive: false,
      zoom: 10,
      attributionControl: false,
    });

    let allSectionsCoordinates = [];
    Object.entries(forestSectionDetails).forEach((item) => {
      let values = JSON.parse(item[1][0]);
      values = values.features[0].geometry.coordinates[0][0];
      allSectionsCoordinates = allSectionsCoordinates.concat(values);
    })

    map.fitBounds(turf.bbox(turf.lineString(allSectionsCoordinates)), {padding: 75, duration: 0});
    map.on('load', () => paintForestTrails(map, forestTrails));
  });
}

const paintForestTrails = (map, forestTrails) => {
  map.on('load', () => {
    renderTrailsOnMap(forestTrails, map, false);
  });
};
