import { validateTermsAcceptance } from 'checkouts/terms_checkbox_toggle';
import { validateCustomerInformation } from 'checkouts/customer_information';
import { validateBillingAddress } from 'checkouts/billing_address';
import { showCheckoutButton } from 'checkouts/payment_button';

// Guard against double submissions when
// using the enter key
var formSubmissionInProgress = false;

function stripeTokenHandler(token){
  let $paymentGatewayField = $('#payment_gateway');
  $paymentGatewayField.val("stripe");

  let $customerTokenField = $('#customer_token');
  $customerTokenField.val(token).parents('form').submit();
}

function updateButtonState() {
  formSubmissionInProgress = true;
  $('#terms_of_purchase_accepted_checked').attr('disabled', true);

  let $button = $('.js-checkout-button');
  $button.html('Processing...');
  $button.off('click.enabled')
  $button.css('pointer-events', 'none');
}

function resetButtonState(){
  formSubmissionInProgress = false;
  $('#terms_of_purchase_accepted_checked').attr('disabled', false);

  let $button = $('.js-checkout-button');
  $button.html('Complete Checkout');
  $button.css('pointer-events', '');
  bindButtonClick();
}

function setupStripe() {
  let stripePublicKey = $("meta[name='stripe-public-key']").attr("content");
  var stripe = Stripe(stripePublicKey);

  let classes = {
    base: 'form-control py-2',
    focus: 'stripe-container-focus',
  }

  let style = {
    base: {
      color: '#004d54',
      fontFamily: 'Lato, "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      letterSpacing: '0.25px',
      '::placeholder': {
        color: '#cdd4ca'
      },
    },
    invalid: {
      color: '#aa5043',
    }
  };

  let latoFont = { cssSrc: 'https://fonts.googleapis.com/css?family=Lato' }

  let elements = stripe.elements({ fonts: [latoFont] });

  var cardNumber = elements.create('cardNumber', { classes: classes, style: style });
  cardNumber.mount('#card-number');

  let cardExpiry = elements.create('cardExpiry', { classes: classes, style: style });
  cardExpiry.mount('#card-expiry');

  let cardCvc = elements.create('cardCvc', { classes: classes, style: style });
  cardCvc.mount('#card-cvc');

  let $form = $('#payment-form');

  $form.on('submit', function(event) {
    event.preventDefault();

    if (formSubmissionInProgress) {
      return
    };

    updateButtonState();

    // Intentionally avoid short-circuit validation
    let validateCustomerInfo = validateCustomerInformation();
    let validAddress = validateBillingAddress();
    let termsAccepted = validateTermsAcceptance();

    if(validateCustomerInfo && validAddress && termsAccepted) {
      stripe.createToken(cardNumber).then(function(result) {
        if (result.error) {
          // Inform the user if there was an error
          $('.card-errors').html(result.error.message);
          resetButtonState();
        } else {
          // Send the token to the server
          stripeTokenHandler(result.token.id);
        }
      });
    } else {
      resetButtonState();
    }
  });
}

function bindButtonClick() {
  let $checkoutButton = $('.js-checkout-button');

  $checkoutButton.on('click.enabled', function(e) {
    e.preventDefault();

    $('#payment-form').trigger('submit');
  });
}

// Force address form to submit stripe form when pressing
// the 'Enter' key
function bindEnterKeyToAddressForm() {
  $('#payment_completion_form').on('keypress', function(event) {
    if (event.keyCode == 13) {
      $('#payment-form').trigger('submit');
    };
  });
}

$(document).ready(function(){
  if ($('form#payment-form').length > 0) {
    setupStripe();
    bindButtonClick();

    // Only display the button once both functions
    // above have set up their event handlers
    showCheckoutButton();

    bindEnterKeyToAddressForm();
  }
});
