export function validateBillingAddress(){
  let allValid = true;

  $('.js-billing-address-field-required').each(function(){
    if ($.trim($(this).val()).length == 0){
      $(this).addClass('is-invalid');
      var errorMessage = $(this).parent().find('.error-message');
      if(errorMessage.length == 0) {
        errorMessage = $("<span class='error-message'/>");
        $(this).after(errorMessage);
      }
      errorMessage.html('This field is required.');

      allValid = false;

    } else {
      var errorMessage = $(this).parent().find('.error-message');
      errorMessage.remove();
      $(this).removeClass('is-invalid');
    }
  });

  return allValid;
}
