import { openPopUp } from 'referrals/share-options';

$(() => {
  $(document).on('click', '.js-share-on-facebook-button', function(e){
    e.preventDefault();

    let postToFacebookLink = $(this).data('postToFacebookLink');
    openPopUp(postToFacebookLink);
  });
});
