$(function() {
  let $asyncLoads = $(".js-async-load");

  $asyncLoads.each(function(index, element) {
    let url = $(element).data("url");

    $.get({
      "url": url,
      "success": function(data) { $(element).replaceWith(data); }
    });
  });
});
