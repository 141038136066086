require('jquery-ui/ui/effects/effect-slide');


export function treesDesktopMapListeners(map) {
  $('body').on('click', '.js-tree-marker', function(e) {
    $('.tree-card').removeClass('active');
    $('.js-tree-marker').removeClass('active');
    $(this).addClass('active');
    var tree_card_id = $(this).attr('id').replace('tree_marker', 'tree');
    $('#' + tree_card_id + '').addClass('active');

    // Display corresponding tree card popup.
    let treeId = tree_card_id.replace('tree_', '');
    fetchTreeCard(treeId);
  });

  $('.tree-card').mouseenter(function() {
    $('.js-tree-marker').removeClass('highlight');
    var tree_marker_id = $(this).attr('id').replace('tree', 'tree_marker');
    $('#' + tree_marker_id + '').addClass('highlight');
  });

  $('.tree-card').mouseleave(function() {
    $('.js-tree-marker').removeClass('highlight');
  });
}

export function mapLegendListeners(map) {
  $('.ecommerce .js-map-legend-toggle').click(function() {
    $('.js-map-legend-sidebar').toggle('slide', { direction: 'right' }, 500)
  });
}

function fetchTreeCard(treeId) {
  let params = new URLSearchParams();
  const currentParams = new URLSearchParams(window.location.search)
  if (currentParams.has('url_slug')) {
    params.append('url_slug', currentParams.get('url_slug'));
  }
  if (currentParams.has('tour')) {
    params.append('tour', currentParams.get('tour'));
  }
  if (currentParams.has('entrypath')) {
    params.append('entrypath', currentParams.get('entrypath'));
  }
  Rails.ajax({
    type: 'GET',
    url: "/shop/map/trees/" + treeId,
    data: params.toString(),
  });
}
