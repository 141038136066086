export function submitCheckoutMetadataUpdate(cartUrlSlug, params = {}, onSuccess, onError) {
  $.ajax({
    method: 'PUT',
    url: Routes.checkout_metadata_path({
      url_slug: cartUrlSlug,
    }),
    data: params,
    success: function(data) {
      if (onSuccess) { onSuccess(data) };
    },
    error: function(data) {
      if (onError) { onError(data) };
    },
  });
};
