$(function() {
    $("body.ecommerce").on("change", "#collapsible-filter-container .js-filter-checkbox input[type=checkbox]", () => {
        const $filterContainer = $('#collapsible-filter-container');
        const arborTags = $filterContainer.find('[name="filters[arbor_tags][]"').length;
        const checkedFilters = $filterContainer.find('.js-filter-checkbox input[type=checkbox]:checked').length;

        $filterContainer.find('.js-filters-count').text((arborTags + checkedFilters).toString());
        $filterContainer.find('.js-filters-count-container').removeClass('d-none');
    });

    $("#ecommerce-desktop-trees #js-collapsible-filter-container")
      .on('show.bs.collapse', function () {
        const $container = $('#js-all-trees-left-container');
        $container.find(".overlay").removeClass('d-none');
        $container.css('overflow-y', 'hidden');
      })
      .on('hide.bs.collapse', function () {
        const $container = $('#js-all-trees-left-container');
        $container.find(".overlay").addClass('d-none');
        $container.css('overflow-y', 'scroll');
      });
});