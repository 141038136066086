// this is the functionality for the cancel button on the account
// page which essentially just tries to ajax 'get' the form's
// action and thus reload the form with its original values

$(function() {
  $(document).on('click', '.cancel-link', function(){
    let form = $(this).closest('form');
    form[0].reset();
    $(this).addClass('d-none')
    return false; // prevent page from scrolling to top
  });

  $(document).on('input', '.bpf-form', function(){
    $(this).find('.cancel-link').removeClass('d-none');
  });
});
