$(document).ready(function() {
  let $form = $('.js-paypal-form');
  if ($form.length > 0) {
    let txn_id = localStorage.getItem('bpftxnid');
    if (txn_id) {
      localStorage.removeItem('bpftxnid');
      $('#transaction_id').val(txn_id);
    } else {
      window.location.replace($form.data('next-step-path'));
    }
  }
});
