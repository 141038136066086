$(document).ready(function(){
  if ($('#paypal-button').length > 0) {
    let token = $('#paypal-button').data('clientToken');
    if (token) {
      braintree.client.create({
        authorization: token
      }, initializePaypalButton);
    }
  }
});

function initializePaypalButton(clientErr, clientInstance) {

  // Stop if there was a problem creating the client.
  // This could happen if there is a network error or if the authorization
  // is invalid.
  if (clientErr) {
    console.error('Error creating client:', clientErr);
    return;
  }

  let payPaypalButton = $('#paypal-button');
  let braintreeEnvironment = payPaypalButton.data('env');
  let billingDescription = payPaypalButton.data('billingDescription');
  let callbackURL = payPaypalButton.data('onSuccessCallback');

  // Create a PayPal Checkout component.
  braintree.paypalCheckout.create({
    client: clientInstance
  }, function (paypalCheckoutErr, paypalCheckoutInstance) {

    // Stop if there was a problem creating PayPal Checkout.
    // This could happen if there was a network error or if it's incorrectly
    // configured.
    if (paypalCheckoutErr) {
      console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
      return;
    }

    // Set up PayPal with the checkout.js library
    paypal.Button.render({
      env: braintreeEnvironment,
      style: {
        label: 'paypal',
        tagline: false,
        color: 'white',
        shape: 'rect',
        size: 'responsive',
        height: 45
      },
      payment: function () {
        return paypalCheckoutInstance.createPayment({
          intent: 'capture',
          flow: 'vault',
          currency: 'USD',
          billingAgreementDescription: billingDescription
        });
      },

      onAuthorize: function (data, actions) {
        $('.js-paypal-card').removeClass('d-none');

        return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {

          $.ajax({
            method: 'POST',
            url: callbackURL,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            dataType: 'JSON',
            data: { nonce: payload.nonce },
            success: function(data){

              localStorage.setItem('bpftxnid', data.transaction_id);
              window.location.replace(data['redirect_url']);
            },
            error: function(data){
              var errors = $.parseJSON(data.responseText).errors
              $('.js-paypal-card').html(errors);
            }
          });

        });
      },

      onCancel: function (data) {
        console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
      },

      onError: function (err) {
        console.error('checkout.js error', err);
      }
    }, '#paypal-button').then(function () {

      // The PayPal button will be rendered in an html element with the id
      // `paypal-button`. This function will be called when the PayPal button
      // is set up and ready to be used.
      $('.paypal-wrapper').removeClass('d-none');
      $('.paypal-wrapper').hover(() => {
        $('#paypal-choose').toggleClass('hover-state');
      });

      window.addEventListener('blur', function () {
        if (document.activeElement.tagName.toLowerCase() == 'iframe') {
          $('#paypal-choose').addClass('hover-state');
        }
      });

      window.addEventListener('focus', function () {
        $('#paypal-choose').removeClass('hover-state');
      });
    });
  });
}
