import { renderPointOfInterest, renderTreesOnMap, renderTrailsOnMap, renderSubsections } from "./map_utils";
let forestSectionsDetails;
export function setupSectionsMap(accessToken, forestTrails, sectionDetails, subsectionDetails, pointsOfInterest, treesDetails, mobileView = false) {
  mapboxgl.accessToken = accessToken;

  // make sectionDetails available in the outer scope
  forestSectionsDetails = sectionDetails;

  // Set map properties based on if the user is viewing on a mobile device or not.
  const mapContainer = 'map';
  const isInteractive = !mobileView;
  const mapPadding = 45;

  const map = new mapboxgl.Map({
    container: mapContainer,
    style: 'mapbox://styles/monicapoletti/ckl03ocua066517n3o1fbqqzn',
    interactive: isInteractive,
    attributionControl: false,
  });

  // let's position the zooming of the map using the boundaries of all sections
  let allSectionsCoordinates = [];
  Object.entries(sectionDetails).forEach((item) => {
    let values = JSON.parse(item[1][0]);
    values = values.features[0].geometry.coordinates[0][0];
    allSectionsCoordinates = allSectionsCoordinates.concat(values);
  })

  let pointsOfInterestCoordinates = pointsOfInterest.map(p => p.geometry.coordinates)
  map.fitBounds(turf.bbox(turf.lineString(pointsOfInterestCoordinates.concat(allSectionsCoordinates))), { padding: mapPadding, duration: 0 });

  map.getCanvas().style.cursor = 'pointer';

  map.on('load', function() {
    // Add zoom controls to the map
    if(!mobileView) {
      map.addControl(new mapboxgl.NavigationControl({
        showCompass: false,
      }));
    }

    // Add geolocate control to the map.
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        showUserHeading: true,
        trackUserLocation: true,
      })
    );

    // Section source data needs to have a defined ID in order to conditionally
    // style sections based on interactions. Use the Name property from the
    // GeoJSON data, but keep in mind this name is not guaranteed to be the
    // official section name in the Trees App.
    const hoveredState = []; // used to handle hover states
    for(let section in sectionDetails) {
      const sourceData = JSON.parse(sectionDetails[section][0]);
      const sectionCenter = turf.centroid(sourceData);
      const polygonId = 'section-polygon-' + section;

      // sets initial hover state for each section
      hoveredState[polygonId] = null;

      map.addSource(polygonId, {
        'type': 'geojson',
        'data': sourceData,
        'promoteId': 'Name'
      });

      map.addLayer({
        'id': polygonId,
        'type': 'fill',
        'source': ('section-polygon-' + section),
        'layout': {},
        'paint': {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(200,116,42,0.4)',
            ['boolean', ['feature-state', 'highlight'], false],
            'rgba(0,84,80,0.2)',
            'rgba(203, 191, 171, 0.5)',
          ],
        }
      });

      // this is the only way of adding a border with a width greater than 1px to a polygon
      map.addLayer({
        'id': 'section-polygon-border-' + section,
        'type': 'line',
        'source': polygonId,
        'paint': {
          'line-color': 'rgba(255, 255, 255, 1)',
          'line-width': mobileView ? 3 : 6,
        }
      });

      // When the user moves their mouse over the section layer, we'll update the
      // feature state for the feature under the mouse.
      map.on('mousemove', polygonId, (e) => {
        if (e.features.length > 0) {
          if (hoveredState[polygonId] !== null) {
            map.setFeatureState(
                { source: polygonId, id: hoveredState[polygonId] },
                { hover: false }
            );
          }
          hoveredState[polygonId] = e.features[0].id;
          map.setFeatureState(
              { source: polygonId, id: hoveredState[polygonId] },
              { hover: true }
          );

          // make section label active
          $('#' + polygonId.replace('section-polygon-', 'section_label_')).addClass('active');
        }
      });

      // When the mouse leaves the section layer, update the feature state of the
      // previously hovered feature.
      map.on('mouseleave', polygonId, () => {
        if (hoveredState[polygonId] !== null) {
          map.setFeatureState(
              { source: polygonId, id: hoveredState[polygonId] },
              { hover: false }
          );
        }
        hoveredState[polygonId] = null;

        // remove active from section label
        $('#' + polygonId.replace('section-polygon-', 'section_label_')).removeClass('active');
      });

      // Create a DOM element for a section name label. This will be placed on
      // the coordinates specified in the polygon properties if they exist, or
      // on the center of the polygon as a fallback.
      // Only render the label if not seing the forest all tress page.
      if (!treesDetails) {
        const el = document.createElement('div');
        el.className = 'ecommerce';
        if (mobileView){
          el.className = 'js-mobile-section-label';
        } else {
          el.className = 'js-section-label';
        }
        el.id = 'section_label_' + section;
        el.innerHTML = '<span>' + sectionDetails[section][1]+ '</span>'
        if (sourceData.features[0].properties.hasOwnProperty("Label_Lat") &&
          sourceData.features[0].properties.hasOwnProperty("Label_Long")) {
          new mapboxgl.Marker(el)
            .setLngLat([sourceData.features[0].properties["Label_Long"],
                        sourceData.features[0].properties["Label_Lat"]])
            .addTo(map);
        } else {
          new mapboxgl.Marker(el)
            .setLngLat(sectionCenter.geometry.coordinates)
            .addTo(map);
        }
      }

      renderSubsections(JSON.parse(subsectionDetails[section]), map);
    }

    if (!mobileView) {
      let forestSectionsContainer = $('.ecommerce').find('.js-forest-sections')[0];
      const forestSections = [];
      $('.ecommerce').find('.forest-section').each(function(i, obj) {
        forestSections.push("#" + obj['id']);
      });

      if (forestSectionsContainer) {
        forestSectionsContainer.map = map;
        forestSectionsContainer.useScrollDebounce = true;
        forestSectionsContainer.forestSections = forestSections;
      }

      map.on('click', function (e) {
        // Remove the scroll listener to prevent highlighting auto-scrolling sections.
        forestSectionsContainer && forestSectionsContainer.removeEventListener('scroll', highlightSectionsOnScroll);

        // Determine if the click occurred on a section label marker (or on the text
        // within a section label marker), which has do be done first since markers
        // cannot be queried as layers. If a label marker was clicked, determine the
        // corresponding section polygon ID.
        let sectionPolygonId = ""
        if (e.originalEvent.target.classList.contains('js-section-label') ||
            e.originalEvent.target.parentElement.classList.contains('js-section-label')) {
          let sectionLabelId = e.originalEvent.target.id;
          if (!sectionLabelId) {
            sectionLabelId = e.originalEvent.target.parentElement.id;
          }
          sectionPolygonId = sectionLabelId.replace('section_label_', 'section-polygon-');
        } else {
          // If a label wasn't clicked, check to see if the actual section polygon
          // was clicked and save the ID if so, breaking after the first section
          // polygon is found since these polygons never overlap. Also add the
          // "active" CSS to the corresponding label.
          const clickFeatures = map.queryRenderedFeatures(e.point)
          let sectionPolygonClicked = false;
          for (let i in clickFeatures) {
            if (clickFeatures[i].layer.id.startsWith('section-polygon-')) {
              sectionPolygonId = clickFeatures[i].layer.id;
              sectionPolygonClicked = true;
              break;
            }
          }

          if (!sectionPolygonClicked && forestSectionsContainer) {
            forestSectionsContainer.addEventListener('scroll', highlightSectionsOnScroll);
          }
        }

        // Make sure all section polygon layers are in their non-highlighted state,
        // except for the corresponding section polygon if it exists. That one
        // should be highlighted and the corresponding info card should be scrolled
        // into view.
        const allFeatures = map.queryRenderedFeatures().filter(function(f) {
          return f.layer.id.startsWith('section-polygon-');
        });
        // Reset all to non-highlighted
        allFeatures.forEach(function(f) {
          map.setFeatureState(f, { highlight: false });
        })
        // Get selected layer
        const selectedFeature = allFeatures.find(function(f) {
          return f.layer.id === sectionPolygonId
        })

        // If there's nothing selected, just return.
        if (!selectedFeature) return

        // Darken clicked section.
        map.setFeatureState(selectedFeature, { highlight: true });

        const $mapCards = $('.map-cards');
        if ($mapCards.length) {
          const sectionID = selectedFeature.layer.id.match(/\d+/)[0];
          const sliderIndex = $mapCards.find('.forest-section-carousel-card').index($mapCards.find(`[data-section="${sectionID}"]`));

          if (sliderIndex > -1) {
            const swiper = document.querySelector('.map-card-swiper-container.swiper-container').swiper;
            swiper.slideTo(sliderIndex);
          }
        }

        if (forestSectionsContainer) {
          const $verticalContainer = $('.js-forest-sections');

          // Scroll section card into view.
          const sectionCardId = selectedFeature.layer.id.replace('section-polygon-', 'forest_section_card_');
          const cardTopPosition = $('#' + sectionCardId + '').offset().top
          const cardTopPadding = 150  // Scroll the card to display below the ~100px navbar.
          const scrollPosition = $verticalContainer.scrollTop() + cardTopPosition - cardTopPadding

          $verticalContainer.animate({
            scrollTop: scrollPosition,
          }, 300).promise().done(function(){
            // Add scroll hander back once the forest cards are done auto-scrolling.
            forestSectionsContainer.addEventListener('scroll', highlightSectionsOnScroll);
          });
        }
      });
    }

    // Render POI markers if any.
    renderPointOfInterest(pointsOfInterest, map);
    // Render trees if we all in all-sections page
    renderTreesOnMap(treesDetails, map, mobileView);
    // Render Trails onto map
    renderTrailsOnMap(forestTrails, map, mobileView);
  });

  // this happens only once when that map has loaded
  map.once('idle', (e) => {
    // returns a tuple made of [id, data]
    const firstSection = Object.entries(forestSectionsDetails).find((section) => {
      // [1][2] is the index; first one = 0
      return section[1][2] === 0;
    });
    const firstSectionId = firstSection[0];

    // highlight section polygon
    const sectionPolygonId = 'section-polygon-' + firstSectionId;
    const polygonFeature = map.queryRenderedFeatures({ layers: [sectionPolygonId] })[0];

    if (polygonFeature) {
      map.setFeatureState(polygonFeature, { highlight: true });
    }

    // bind listener to the vertical-scroll forest sections container only after
    // the map is fully loaded
    const $ecommerce = $('.ecommerce');
    const forestSections = $ecommerce.find('.js-forest-sections')[0]
    forestSections && forestSections.addEventListener('scroll', highlightSectionsOnScroll);

    $ecommerce.on('click', '.map-cards-nav-btn', function (e) {
      const sectionID = $(e.currentTarget).parents('.map-cards').find('.swiper-slide-active').data('section');
      resetMapHighlightSection(map, sectionID);
    });
  });
}

const resetMapHighlightSection = (map, section) => {
  const allFeatures = map.queryRenderedFeatures();
  for (let i in allFeatures) {
    if (allFeatures[i].layer.id.startsWith('section-polygon-')) {
      map.setFeatureState(allFeatures[i], { highlight: false });
    }
  }

  // Still to do: Highlight the section layer.
  const polygonFeature = map.queryRenderedFeatures({ layers: [`section-polygon-${section}`] })[0];

  if (polygonFeature) {
    map.setFeatureState(polygonFeature, { highlight: true });
  }
};

function highlightSectionsOnScroll(e) {
  const map = e.currentTarget.map;
  const forestSections = e.currentTarget.forestSections;
  let useScrollDebounce = e.currentTarget.useScrollDebounce;

  if (useScrollDebounce) {
    useScrollDebounce = false;

    for (let i = 0; i < forestSections.length; i++) {
      if($(forestSections[i]).visible(true)) {
        const sectionID = $(forestSections[i]).parent().attr('id').replace('forest_section_card_', '');
        resetMapHighlightSection(map, sectionID);
      }
    }

    setTimeout(function () { useScrollDebounce = true; }, 750);
  }
}

$(function() {
  $('#scroll_to_map').on('click', function(){
    $('.js-forest-sections').animate({
      scrollTop: $('#map').offset().top,
    }, 500);
  });
});
