export class Slider {
  constructor(rangeElement) {
    this.rangeElement = rangeElement;
    this.initialize();
  }

  initialize() {
    this.label = this.rangeElement.parentNode.querySelector(".slider-label");

    // Inspired by https://css-tricks.com/sliding-nightmare-understanding-range-input/#the-range-progress-fill-component
    this.style.setProperty('--min', this.min);
    this.style.setProperty('--max', this.max);

    this.update();
    this.rangeElement.addEventListener('input', () => this.update());
  }

  update() {
    this.style.setProperty('--val', this.value);
    this.updateLabel();
  }

  keepSyncedWith(input) {
    this.rangeElement.addEventListener("input", e => {
      input.value = this.value;
      input.dispatchEvent(new Event("input"));
    });

    input.addEventListener("input", e => {
      this.value = input.value;
      this.update();
    });
  }

  updateLabel() {
    // Inspired by https://css-tricks.com/value-bubbles-for-range-inputs
    const newVal = Number(((this.value - this.min) * 100) / (this.max - this.min));
    this.label.innerHTML = `$${this.value}`;

    // Sorta magic numbers based on size of the UI thumb
    this.label.style.left = `calc(${newVal}% + (${16 - newVal * 0.32}px))`;
  }

  get min() {
    return this.rangeElement.min;
  }

  get max() {
    return this.rangeElement.max;
  }

  get value() {
    return this.rangeElement.value;
  }

  set value(newValue) {
    this.rangeElement.value = newValue;
  }

  get style() {
    return this.rangeElement.style;
  }
}
