$(function() {
  // Enable bootstrap's native tooltips
  $('[data-toggle="tooltip"], .has-tooltip').tooltip();

  // Setup custom tooltips
  $(document).on("click", ".close-tooltip", function(e) {
    $(e.target).closest('.tool-tip').hide();
  });

  $(document).on("click", '.open-tooltip', function(e) {
    e.preventDefault();
    let $targetTooltip = $($(this).data('target'));

    let $trigger = $(e.target);
    let triggerBottomPosition = $trigger.position().top + $trigger.innerHeight();

    $targetTooltip.css({ top: triggerBottomPosition }); // Position tooltip below the element that opened it
    $targetTooltip.toggle();
  });
});
