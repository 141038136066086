$(function() {
    $(".ecommerce .js-horizontal-scrolling-carousel").each(function() {
        const $carousel = $(this);
        setCarouselTotalSlides($carousel);
        createCarouselIndicators($carousel);
        toggleCarouselControls($carousel);
    });

    // reset carousels' scroll. this is done so when a carousel has been scrolled and the page is refreshed,
    // it starts again in the first card
    $('.ecommerce .js-horizontal-scrolling-carousel .js-horizontal-scrolling-carousel-card-deck').scrollLeft(0);

    // prev/next handlers
    $(".ecommerce").on('click', '.js-horizontal-scrolling-carousel-handler', function(e) {
        e.stopPropagation();

        const $carousel = $(this).closest('.js-horizontal-scrolling-carousel');
        const direction = $(this).data('handler-direction');

        scrollCarousel(direction, $carousel);
    });
});

const marginBetweenCards = 16; //px

function createCarouselIndicators($carousel) {
    const $indicators = $carousel.find(".js-horizontal-scrolling-carousel-indicators");
    const totalSlides = getTotalSlides($carousel);

    // set visibility:hidden if there's only one slide
    if(totalSlides === 1) {
        $indicators.addClass('invisible');
    }

    $indicators.html("");
    for(let i = 0; i < totalSlides; i++) {
        const indicatorHtml = `<li class="js-horizontal-scrolling-carousel-slide-navigation ${i === 0 ? 'active' : ''}" data-slide-to="${i}"></li>`;

        $indicators.append(indicatorHtml);
    }
}

function toggleCarouselControls($carousel) {
    const slides = $carousel.data('total-slides');
    const currentSlide = $carousel.data('current-slide');

    // hide by default
    $carousel.find(".js-horizontal-scrolling-carousel-handler").addClass('invisible-handler');

    // if it's not the first slide, show prev hanlder
    if(currentSlide !== 0) {
        $carousel.find(".js-horizontal-scrolling-carousel-handler[data-handler-direction='prev']").removeClass('invisible-handler');
    }

    // if we are not at the last slide, show next handler
    if(currentSlide !== (slides - 1)) {
        $carousel.find(".js-horizontal-scrolling-carousel-handler[data-handler-direction='next']").removeClass('invisible-handler');
    }
}

function scrollCarousel(direction = 'prev', $carousel) {
    const currentSlide = $carousel.data('current-slide');
    const totalSlides = getTotalSlides($carousel);
    const deckScrollWidth = getDeckScrollWidth($carousel);
    const cardDeckEl = $carousel.find('.js-horizontal-scrolling-carousel-card-deck')[0];

    if(direction === 'prev' && currentSlide > 0) {
        updateCarouselSlide((currentSlide - 1), $carousel)

        cardDeckEl.scrollTo({
            left: ((deckScrollWidth / totalSlides) * (currentSlide - 1)) - marginBetweenCards,
            behavior: "smooth"
        });
    } else if(direction === 'next' && currentSlide < (totalSlides - 1)) {
        updateCarouselSlide((currentSlide + 1), $carousel)

        cardDeckEl.scrollTo({
            left: ((deckScrollWidth / totalSlides) * (currentSlide + 1)) + marginBetweenCards,
            behavior: "smooth"
        });
    }

    // show/hide prev/next handlers
    toggleCarouselControls($carousel);
}

function updateCarouselSlide(newSlide, $carousel) {
    const $indicators = $carousel.find('.js-horizontal-scrolling-carousel-indicators');

    // remove active class from whichever slide is selected
    $indicators.find('li').removeClass('active');

    // set the new slide indicator as active
    $indicators.find("li[data-slide-to='" + newSlide + "']").addClass('active');

    // update the data attr of the carousel with new slide. this is our source of truth.
    $carousel.data('current-slide', newSlide);
}

function getTotalSlides($carousel) {
    const deckScrollWidth = getDeckScrollWidth($carousel);
    const numberOfSlides = deckScrollWidth / $(window).width();

    if((numberOfSlides % 1) < 0.05) {
        return Math.round(numberOfSlides);
    }

    return Math.ceil(numberOfSlides);
}

function setCarouselTotalSlides($carousel) {
    $carousel.data('total-slides', getTotalSlides($carousel));
}

function getDeckScrollWidth($carousel) {
    return $carousel.find('.js-horizontal-scrolling-carousel-card-deck')[0].scrollWidth
}
