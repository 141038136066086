export function showDashboardSuccess (message) {
  var modal = $("#successModal");
  modal.find(".js-message").html(message);
  modal.modal({});
  modal.find('.btn').focus();
}

export function showDashboardError(title, message, details) {
  var modal = $("#errorModal");
  if (title) modal.find(".js-title").html(title);
  if (message) modal.find(".js-message").html(message);
  if (details) modal.find(".js-details").html(details);
  modal.modal({});
  modal.find('.btn').focus();
}

export function showReferralPrompt() {
  var modal = $("#referralPromptModal");
  modal.modal({});
  var ctaLink = $('#referralPrompt').attr('href');
  globalEventSend('dashboardReferralPromptDisplayed', { cta: ctaLink });
  modal.find('#referralPrompt').focus();
}
