// This is used to start and stop the background video on the ecommerce welcome
// page while the intro video is playing so that we do not have two videos
// playing on the page at the same time

$(function(){
  const background_video_iframe = $(".background-video").find('iframe')[0];
  const intro_video_iframe = $(".welcome-video").find('iframe')[0];

  if (background_video_iframe && intro_video_iframe) {
    $('.intro-video-modal').on('show.bs.modal', function (e) {
      // pause bg video
      background_video_iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');

      // start intro video from beginning
      intro_video_iframe.contentWindow.postMessage('{"event":"command","func":"seekTo","args":[0, true]}', '*');
      intro_video_iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    })

    $('.intro-video-modal').on('hide.bs.modal', function (e) {
      // play bg video
      background_video_iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');

      // pause intro video
      intro_video_iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    })
  }
})
