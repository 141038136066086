function updatePersonalMessage($input, name) {
  if (name.length) {
    name = ` ${name}`;
  }

  $input.val($input.data("template").replace(/{{name}}/, name));
}

$(function() {
  let $form = $(".js-referral-form");

  if ($form.length) {
    // Set up listener on the document so it works even after
    // the form container has been replaced upon successful submission

    let refereeFirstNameSelector = ".js-referral-referee-first-name";
    let personalMessageSelector = ".js-referral-personal-message";

    $(document).on("keyup", personalMessageSelector, function() {
      $(this).data("manuallyChanged", true);
    });

    $(document).on("input", refereeFirstNameSelector, function() {
      let $personalMessage = $(personalMessageSelector);

      if (!$personalMessage.data("manuallyChanged")) {
        updatePersonalMessage($personalMessage, $(this).val());
      }
    });

    $(document).on('referralFormUpdated', function() {
      let $refereeFirstName = $(refereeFirstNameSelector);
      let $personalMessage = $(personalMessageSelector);

      updatePersonalMessage($personalMessage, $refereeFirstName.val());
    });

    let $refereeFirstName = $(refereeFirstNameSelector);
    let $personalMessage = $(personalMessageSelector);
    updatePersonalMessage($personalMessage, $refereeFirstName.val());
  }
});
