import { datepicker } from 'jquery-ui/ui/widgets/datepicker';
import { Slider } from "checkouts/slider";
import { submitCheckoutMetadataUpdate } from "checkouts/metadata_update";

function minimumStartDate(downPaymentAmount) {
  if (downPaymentAmount > 0) {
    return new Date($('#minimum-start-date-with-down-payment').html());
  }
  return new Date($('#minimum-start-date').html());
}

export function setUpDatePicker(downPaymentAmount, downPaymentRequiredWhenDeferred, $startDateContainer) {
  // To do: there is a bug here. It's grabbing the first `js-start-date:visible`, and looking at its maxMonthsOut
  // this is incorrect, as different payment plans might have different deferral maxes. Leaving for now as
  // they are currently all the same value, and we have no plans to change this. But the code is ostensibly
  // set up to handle different maxMonthsOut per plan type.
  if ($startDateContainer.find('.js-start-date:visible').length === 0) {
    return;
  }

  const monthsOut = $startDateContainer.find('.js-start-date:visible').data('maxMonthsOut');
  const dateSentence = $startDateContainer.find('.js-start-date:visible').html().trim();
  const defaultDate = (dateSentence.toLowerCase() === 'today') ? minimumStartDate(downPaymentAmount) : new Date(dateSentence);
  const minDate = minimumStartDate(downPaymentAmount);

  $('#datepicker').not('.hasDatePicker').datepicker({
    minDate: minDate,
    maxDate: `+${monthsOut}m`,
    defaultDate: defaultDate,
    markerClassName: 'hasDatePicker',
  });
  $('#datepicker').not('.hasDatePicker').datepicker('option', 'minDate', minDate)

  if (downPaymentRequiredWhenDeferred) {
    let today = new Date($('#minimum-start-date').html()); // Use server date as today
    let deferredStartDate = defaultDate > today;
    $('.js-down-payment-required-message').toggleClass('d-none', !deferredStartDate);
  }
}

$(() => {
  if ($('#customStartDateModal').length == 0) {
    return;
  }

  $(document).on('click', '.js-edit-date', function (event) {
    const $target = $(event.target);
    const summaryCard = $target.parents('.js-summary').length > 0;
    const paymentInfoPage = $target.parents('.purchase-summary').length > 0;
    const $container = $target.parents('.js-start-date-container');
    const downPaymentAmount = $container.closest('.js-payment-option').find('#down_payment:visible').val()
      || $(".js-down-payment-md:visible input[type='number']").val()
      || 0;

    const downPaymentRequiredWhenDeferred = $target.data('down-payment-required-when-deferred')

    setUpDatePicker(downPaymentAmount, downPaymentRequiredWhenDeferred, $container);

    let lastConfirmedDate = $container.find('.js-start-date').html().trim();
    lastConfirmedDate = (lastConfirmedDate.toLowerCase() === 'today') ? minimumStartDate(downPaymentAmount) : new Date(lastConfirmedDate);
    $('#datepicker').datepicker('setDate', new Date(lastConfirmedDate));

    if (downPaymentRequiredWhenDeferred) {
      $('#datepicker').datepicker('option', 'onSelect', function (selectedDate) {
        // Use server date as today
        let today = new Date($('#minimum-start-date').html()).toLocaleDateString(
          "en-US",
          {month: '2-digit', year: 'numeric', day: '2-digit'}
        );
        let deferredStartDate = today !== selectedDate;

        $('.js-down-payment-required-message').toggleClass('d-none', !deferredStartDate);
      });
    }

    $('#confirm-datepicker').off('click');


    $('#confirm-datepicker').on('click', function () {
      let cartUrlSlug = $container.find('.js-start-date:visible').data('cart-slug');
      let selectedDate = $('#datepicker').val();
      let params = { start_date: selectedDate, down_payment: downPaymentAmount }

      if (paymentInfoPage) {
        submitCheckoutMetadataUpdate(cartUrlSlug, { ...params, page: 'payment_info' }, function() {
          $('.js-start-date-field').val(selectedDate);
        });
      } else if (summaryCard) {
        submitCheckoutMetadataUpdate(
          cartUrlSlug,
          { ...params, page: 'checkout', card: true },
          function() {
            let slider = new Slider(document.querySelector(".custom-range"));
            slider.keepSyncedWith(document.querySelector(".js-down-payment-md input[type='number']"));
          },
          function() {
            $(".js-payment-options-error").text("Something went wrong, please try again.");
          },
        );
      } else {
        submitCheckoutMetadataUpdate(
          cartUrlSlug,
          { ...params, page: 'checkout', option: $container.data('plan-name') }
        );
      };
    });
  });
});
