$(function() {
    $("body").on("click", "#ecommerce-mobile-trees #filter-tabs .nav-link", function(e) {
        const targetPosition = $($(this).attr("href")).offset().top;
        const stickyHeader = $("#ecommerce-mobile-trees #collapsible-filter-container #sticky-header").height();
        const scrollPosition = $('.tree-filter-container').scrollTop() + targetPosition - stickyHeader

        $('.tree-filter-container').animate({
          scrollTop: scrollPosition,
        }, 500);
    });
});