$(function() {
  if($('.ecommerce .saved-trees').length) {
    const $saveWishlistBtn = $('.ecommerce .js-save-wishlist-btn')
    const $compareBtnContainer = $('.js-compare-btn-mobile-container');
    const $compareBtn = $('.js-compare-btn');
    const $checkboxContainers = $('.js-compare-trees-checkbox-container');
    const $checkboxes = $checkboxContainers.find('input[type=checkbox]');

    // clear all checkboxes and disable btn on page load
    $checkboxes.prop('checked', false);
    $compareBtn.attr('disabled', 'disabled');
    // hide container
    $compareBtnContainer.addClass('d-none');

    bindCheckbox($checkboxes, $checkboxContainers, $compareBtn, $compareBtnContainer);
    bindSubmit($compareBtn);

    if($saveWishlistBtn.length) {
      $saveWishlistBtn.click(function() {
        $('#wishlist-modal').modal('show');
      });
    }
  }
});
function bindCheckbox($checkboxes, $checkboxContainers, $compareBtn, $compareBtnContainer) {
  // if 2 checkboxes checked, disable all others and enable compare btn
  $checkboxes.change(function() {
    if($checked().length === 2) {
      $unchecked().closest('.js-compare-trees-checkbox-container').addClass('disabled')
      $unchecked().prop('disabled', 'disabled');

      // enable compare btn
      $compareBtn.removeAttr('disabled');
      // show container
      $compareBtnContainer.removeClass('d-none');
    } else {
      $checkboxContainers.removeClass('disabled');
      $checkboxes.removeAttr('disabled');

      // disable compare btn
      $compareBtn.attr('disabled', 'disabled');
      // hide container
      $compareBtnContainer.addClass('d-none');
    }
  })
}

function bindSubmit($compareBtn, $checkbox) {
  $compareBtn.click(function() {
    const params = new URLSearchParams(window.location.search);
    const treeIds = $checked().map(function() {
      return $(this).val()
    }).get();

    treeIds.forEach((id) => params.append('tree_ids[]', id));

    window.location.href = `${$compareBtn.data('compare-trees-path')}?${params.toString()}`
  });
}

function $checked() {
  return $('.js-compare-trees-checkbox-container').find('input[type=checkbox]:checked');
}
function $unchecked() {
  return $('.js-compare-trees-checkbox-container').find('input[type=checkbox]:not(:checked)');
}



