import Rails from 'rails-ujs';

$(function() {
  $(document).on('change', '#terms_of_purchase_accepted_checked', function() {
    Rails.fire(this.form, 'submit');
    $('.js-checkout-button').prop('disabled', true);
  })
});

export function validateTermsAcceptance() {
  let termsAccepted = $('#terms_of_purchase_accepted_checked').prop('checked');
  if (!termsAccepted) {
    let $termsOfPurchaseForm = $('#edit_terms_of_purchase_form');
    $termsOfPurchaseForm.addClass('alert-danger');

    var errorMessage = $termsOfPurchaseForm.parent().find('.error-message');
    if(errorMessage.length == 0) {
      errorMessage = $("<span class='error-message'/>");
      $termsOfPurchaseForm.after(errorMessage);
    }

    errorMessage.html('Please agree to the terms in order to continue.');
  }

  return termsAccepted;
}
