$(function() {

    bindPreviewOnLoad();

    // Set up progressive loading for all carousel photos that exist on the rendered page.
    let carouselPhoto = $('.carousel.progressive-image-loading')
    carouselPhoto.each(function() {
        progressiveLoadPhoto($(this));
    })

    // Load high-quality photos as you scroll through the carousel.
    $("body").on("slide.bs.carousel", ".carousel.progressive-image-loading", function(ev) {
        let carouselPhoto = $(ev.relatedTarget).find(".carousel-photo[data-photo-hq]");
        let $photoPreview = $(carouselPhoto).find('.photo-preview')

        createLqPhoto($photoPreview.attr('src'), $photoPreview);

        if($(carouselPhoto).find('.photo-hq').length === 0) {
            const hqPhoto = createHqPhoto($(carouselPhoto).data('photo-hq'), $photoPreview);

            appendHqPhoto(hqPhoto, carouselPhoto);
        }

    });
    /***** END CAROUSEL *****/

    let singleImage = $('.ecommerce').find(".js-progressive-image-loading-single-image[data-photo-hq]");

    singleImage.each(function() {
        // 1. load preview image and show it
        const previewPhoto = $(this).find('.photo-preview');
        createLqPhoto(previewPhoto.attr('src'), previewPhoto);

        const hqPhoto = createHqPhoto($(this).data('photo-hq'), previewPhoto);

        appendHqPhoto(hqPhoto, $(this));
    });
});

export function progressiveLoadPhoto(targetNode) {
    const $carouselItem = targetNode.find('.carousel-item').first();
    const $carouselPhoto = $carouselItem.find('.js-progressive-image-loading-carousel');
    // 2. load hq image for visible/first slide
    if($carouselItem.hasClass('active')) {
        const $previewPhoto = $carouselPhoto.find('.photo-preview');
        const hqPhoto = createHqPhoto($carouselPhoto.data('photo-hq'), $previewPhoto);

        appendHqPhoto(hqPhoto, $carouselPhoto);
    }
}

function bindPreviewOnLoad() {
    $('img.photo-preview').each(function () {
        const $self = $(this);
        $self[0].onload = function () {
            $self.addClass('loaded');
        };
    })
}

function appendHqPhoto(hqPhoto, targetNode) {
    let appendTo = targetNode;

    // some of our images are within an anchor, so we add the hq image inside of it
    if(targetNode.next('a').length > 0) {
        appendTo = targetNode.find('a');
    }


    // display gradient overlay if found
    const overlay = appendTo.siblings(".js-image-gradient-overlay");
    if(overlay.length) {
        overlay.removeClass('d-none');
    }

    appendTo.find('.photo-preview').after(hqPhoto);
}

function createHqPhoto(hqPhotoSrc, $previewPhoto) {
    const hqPhoto = new Image();

    // Disable lazy loading in our test env so our snapshots work properly
    if(process.env.RAILS_ENV !== 'test') {
        hqPhoto.setAttribute('loading', "lazy");
    }

    hqPhoto.classList.add('photo-hq', 'embed-responsive-item');
    hqPhoto.src = hqPhotoSrc;
    hqPhoto.alt = $previewPhoto.attr('alt');
    hqPhoto.onload = function () {
        hqPhoto.classList.add('loaded');
        $.when(
          $previewPhoto.fadeOut()
        ).done(function() {
            $previewPhoto.remove();
        });
    };

    return hqPhoto;
}

function createLqPhoto(lqPhotoSrc, $previewPhoto) {
    const lqPhoto = new Image();
    lqPhoto.src = lqPhotoSrc;
    lqPhoto.onload = function () {
        $previewPhoto.addClass('loaded');
    };

    return lqPhoto;
}