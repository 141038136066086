function initPaymentMethodForm($form) {
  let $newCardFields = $form.find(".js-new-card-fields");

  const OPTION_SAVED_CARD = "saved_card";
  const OPTION_NEW_CARD = "new_card";
  let $radio = $("input[name='payment[method]']");

  function selectedOption() {
    return $radio.filter(":checked").val();
  }

  function isSelected(option) {
    return (selectedOption() == option);
  }

  function updateNewCardFieldsVisibility() {
    $newCardFields.toggleClass("d-none", !isSelected(OPTION_NEW_CARD));
  }

  function canSubmitNewCardFields() {
    return $newCardFields.hasClass("js-complete");
  }

  function canSubmitForm() {
    return (isSelected(OPTION_SAVED_CARD) || canSubmitNewCardFields());
  }

  function updateSubmitButtonState() {
    $form.find("input[type=submit]").attr("disabled", !canSubmitForm());
  }

  $radio.on("change", function() {
    updateNewCardFieldsVisibility();
    updateSubmitButtonState();
  });

  $form.on("ajax:before", function() {
    $form.find(".js-base-error").text("");
  }).on("ajax:success", function(event) {
    let data = event.detail[0];

    window.location.replace(data.redirect_url);
  }).on("ajax:error", function(event) {
    let data = event.detail[0];

    $form.find(".js-base-error").text(data.error);
  });

  $radio.attr("disabled", false);
  updateNewCardFieldsVisibility();
  updateSubmitButtonState();
}

function initPaymentAmountForm($form) {
  const CUSTOM_AMOUNT_DONE_TYPING_TIMEOUT = 500;
  const OPTION_CUSTOM = "custom";
  const OPTION_PAYOFF = "payoff";

  let $radio = $form.find("input[name='payment[amount]']");
  let customAmountTypingTimer;
  let $customAmountContainer = $form.find(".js-custom-amount");
  let $customAmountInput = $customAmountContainer.find("input");

  function customAmountErrorMessage() {
    let $input = $customAmountInput;
    let value = $input.val();
    let message;

    if (value) {
      let min = $input.attr('min');
      let max = $input.attr('max');

      if (value < parseInt(min) && parseInt(min) != 0 ) {
        message = "$" + min + " minimum";
      } else if (value > parseInt(max) ) {
        message = "It looks like you’ve entered a full payment. Please select the Pay-off option."
      } else if (!validCustomAmount()) {
        message = "Please enter a whole dollar amount";
      }
    }

    return message;
  }

  function updateSavings(paymentAmountSelection) {
    if (isSelected(OPTION_PAYOFF) || isSelected(OPTION_CUSTOM) && canGetSavingsForCustomAmount()) {
      let $savingsContainer = $form.find(".js-savings");

      let fadeTimer = setTimeout(function() {
        $savingsContainer.fadeTo(0.2, 0.5);
      }, 200);

      $.get(
        $savingsContainer.data("url"),
        { amount: selectedOption(), custom_amount: $customAmountInput.val() }
      ).then(function(data) {
        clearTimeout(fadeTimer);
        // Intentionally not using $savingsContainer because it might have been
        // replaced by another callback like this one
        $form.find(".js-savings").replaceWith(data);
      });
    }
  }

  function updateCustomAmountState() {
    if (isSelected(OPTION_CUSTOM)) {
      softDisable($customAmountInput, false);
      $customAmountInput.focus();
    } else {
      softDisable($customAmountInput, true);
    }
  }

  function isWholeNumber(value) {
    return (/^\d+$/).test(value);
  }

  function validCustomAmount() {
    return !isSelected(OPTION_CUSTOM) || isWholeNumber($customAmountInput.val());
  }

  function canSubmitForm() {
    return $form[0].checkValidity() && validCustomAmount();
  }

  function canGetSavingsForCustomAmount() {
    return $customAmountInput.val() == "" || ($customAmountInput[0].checkValidity() && isWholeNumber($customAmountInput.val()));
  }

  function updateSubmitButtonState() {
    $form.find("input[type=submit]").attr("disabled", !canSubmitForm());
  }

  function updateCustomAmountError() {
    let $customAmountErrorMessage = $form.find(".js-custom-amount-error-message");
    let message = customAmountErrorMessage();

    if (isSelected(OPTION_CUSTOM) && message) {
      $customAmountErrorMessage.html(message).show();
      $customAmountContainer.addClass("field_with_errors");
    } else {
      $customAmountErrorMessage.html("").hide();
      $customAmountContainer.removeClass("field_with_errors");
    }
  }

  function selectedOption() {
    return $radio.filter(":checked").val();
  }

  function isSelected(option) {
    return (selectedOption() == option);
  }

  function softDisable($input, disabled) {
    // Styles the input to look disabled. Needed because some browsers
    // will not listen for events on actual disabled inptus
    $customAmountInput.attr("readonly", disabled);
    $customAmountInput.attr("required", !disabled);
    $customAmountInput.toggleClass("cursor-pointer", disabled);
    if (disabled) {
      $customAmountInput.attr("tabindex", -1);
    } else {
      $customAmountInput.removeAttr("tabindex");
    }
  }

  $radio.on("change", function() {
    updateCustomAmountState();
    updateCustomAmountError();
    updateSavings();
    updateSubmitButtonState();
  });

  $customAmountInput.on("input", function() {
    clearTimeout(customAmountTypingTimer);
    updateCustomAmountError();

    customAmountTypingTimer = setTimeout(
      function() {
        updateSavings();
        updateCustomAmountError();
      },
      CUSTOM_AMOUNT_DONE_TYPING_TIMEOUT
    );

    updateSubmitButtonState();
  });

  $customAmountInput.on('click', function() {
    if (isSelected(OPTION_PAYOFF)) {
      $radio.filter('#payment_amount_custom').click();
    }
  });

  $form.on("submit", function(e) {
    // Don't submit form if custom amount is not a whole number.
    // The submit button should be already disabled but some browsers
    // will let you submit values like '10.' with the enter key
    if (!validCustomAmount()) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    // Don't send the the custom amount param if payoff is selected
    if (isSelected(OPTION_PAYOFF)) {
      $customAmountInput.attr("disabled", true);
    }
  });

  $radio.attr("disabled", false);
  updateCustomAmountState();
  updateCustomAmountError();
  updateSubmitButtonState();
}

$(document).ready(function() {
  let $paymentAmountForm = $(".js-payment-amount-form");
  let $paymentMethodForm = $(".js-payment-method-form");

  if ($paymentAmountForm.length) {
    initPaymentAmountForm($paymentAmountForm);
  }

  if ($paymentMethodForm.length) {
    initPaymentMethodForm($paymentMethodForm);
  }
});
