$(function() {
  // tree details page footer
  const stickyTreeDetailsPageFooterTrigger = $('#sticky-footer-trigger');
  if (stickyTreeDetailsPageFooterTrigger.length) addTreeDetailsPageStickyFooterListener(stickyTreeDetailsPageFooterTrigger);

  // all trees page footer
  if ($('#js-all-trees-sticky-footer').length) {
    const $footer = $('#js-all-trees-sticky-footer');
    addAllTreesPageStickyFooterShowTimeout();

    // for desktop
    if($('#js-all-trees-left-container').length) {
      addAllTreesPageStickyFooterScrollListener($('#js-all-trees-left-container'));
    }

    // for mobile
    if($('#ecommerce-mobile-trees #list-view').length) {
      addAllTreesPageStickyFooterScrollListener($('#ecommerce-mobile-trees #list-view'));

      // bind event of the close button of the sticky footer to reset the max-height of container
      $('#ecommerce-mobile-trees').on('click', '#js-sticky-footer-close-btn', function() {
        setTimeout(() => {
          setListViewContainerMaxHeight('');
          $footer.hide();
        }, 100);
      });
    }
  }
});

// all trees page footer SCROLL listener
function addAllTreesPageStickyFooterScrollListener($scrollContainer) {
  const thresholdPercentage = 25;

  // since we are going to bind to the scroll event, let's take it easy with memory and use a 500ms debounce
  const debounceMs = 500;
  let useScrollDebounce = true;

  $scrollContainer.on('scroll', function() {
    if (useScrollDebounce) {
      useScrollDebounce = false;

      // what we do here:
      // we get how much percentage has been scrolled from the left scrollable container.
      // the full height of the scrollable container = ($('#js-all-trees-left-container').prop('scrollHeight') - $('#js-all-trees-left-container').height())
      // the *current* scroll position = $('#js-all-trees-left-container').scrollTop()
      // so we do a rule of 3:
      // 100% = ($('#js-all-trees-left-container').prop('scrollHeight') - $('#js-all-trees-left-container').height())
      // x = ('#js-all-trees-left-container').scrollTop()
      const scrollPercentage = 100 * $scrollContainer.scrollTop() / ($scrollContainer.prop('scrollHeight') - $scrollContainer.height());

      if(scrollPercentage >= thresholdPercentage) {
        showAllTreesPageStickyFooter();
      }

      setTimeout(() => {
        useScrollDebounce = true;
      }, debounceMs);
    }
  });
}

// all trees page footer TIME listener
let allTreesPageStickyFooterTimeout;
function addAllTreesPageStickyFooterShowTimeout() {
  const secondsToAutoShowFooter = 60;

  allTreesPageStickyFooterTimeout = setTimeout(() => {
    showAllTreesPageStickyFooter()
  }, secondsToAutoShowFooter * 1000); // times 1000, because we need ms

}

function showAllTreesPageStickyFooter() {
  const $footer =  $('#js-all-trees-sticky-footer');

  // display footer
  $footer.removeClass('d-none');

  // adjust max-height of mobile list view container to include the sticky footer not-expanded
  setListViewContainerMaxHeight(`- ${$footer.outerHeight()}px`);

  // unbind the scroll listener + stop the auto-show timer
  $('#js-all-trees-left-container, #ecommerce-mobile-trees #list-view').off('scroll');
  clearTimeout(allTreesPageStickyFooterTimeout);
}

let originalListViewMaxHeight;
function setListViewContainerMaxHeight(deltaPx) {
  const $listView = $('#ecommerce-mobile-trees #list-view');

  if(!originalListViewMaxHeight) {
    const currentMaxHeight = $listView.css('max-height');
    originalListViewMaxHeight = currentMaxHeight;
  }

  $listView.css('max-height', `calc(${originalListViewMaxHeight} ${deltaPx})`);
}

// tree details page footer listener
function addTreeDetailsPageStickyFooterListener(stickyTreeDetailsPageFooterTrigger) {
  $(document).on('scroll', function() {
    const stickyFooter = $('.sticky-footer');
    if ( $(this).scrollTop() >= stickyTreeDetailsPageFooterTrigger.position().top ){
      if ( !stickyFooter.is(':visible') ) {
        stickyFooter.addClass('d-block');
      }
    } else if ( stickyFooter.is(':visible') ) {
      stickyFooter.removeClass('d-block');
    }
  });
}

export function footerHeight() {
  return parseInt($('.sticky-footer:visible').css('height'));
}
