$(document).on("show.bs.collapse", function(event) {
  var button = $("[data-target='#" + event.target.id + "']");

  button.text(button.data("expanded-text"));
});

$(document).on("hide.bs.collapse", function(event) {
  var button = $("[data-target='#" + event.target.id + "']");

  button.text(button.data("collapsed-text"));
});
