export function treesMobileMapListeners(map, treeMarkers, treeCards) {
  function highlightCard(tree_card_id) {
    const card_left_position = $('#' + tree_card_id + '').offset().left
    const card_left_padding = ($('#card-carousel').width() - $('#' + tree_card_id + '').width()) / 2
    const scroll_position = $('#card-carousel').scrollLeft() + card_left_position - card_left_padding

    $('#card-carousel').animate({
      scrollLeft: scroll_position,
    }, 1000);
  }

  $("#card-carousel").swipe( {
    swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
      let scrollPosition = $('#card-carousel').scrollLeft();
      if (direction == 'left') {
        scrollPosition += $('.map-tree-card').outerWidth(true);
      } else {
        scrollPosition -= $('.map-tree-card').outerWidth(true);
      }
      $('#card-carousel').animate({
        scrollLeft: scrollPosition,
      }, 200, "linear", function() {
        highlightMobileTreeCardsOnSwipe(map, treeCards, treeMarkers);
      });
    },
    // Only scroll and animate for a swipe greater than 40 px.
     threshold:40
  });
  
  $('body').on('click', '.js-tree-marker', function(e) {
    $('.js-tree-marker').removeClass('active');
    $('.tree-card').removeClass('active');
    $(this).addClass('active');

    var treeCardId = $(this).attr('id').replace('tree_marker', 'tree');
    highlightCard(treeCardId); 
  });
}

export function highlightMobileTreeCardsOnSwipe(map, treeCards, treeMarkers) {
  $('.js-tree-marker').removeClass('active');

  for (let i = 0; i < treeCards.length; i++) {
    if($(treeCards[i]).visible()) {
      let treeMarker = treeMarkers.find(
        marker => marker.tree_id === treeCards[i].split("_")[1]
      );
      map.easeTo({
        center: treeMarker.marker.getLngLat(),
        zoom: 20
      });
      $('#' + treeMarker.id + '').addClass('active')  
    }
  }
}
