$(document).ready(function() {
  $(".skip-link").click(function() {
    var $skipTo = $($(this).attr('href'));

    // Allow element to be briefly focused via js
    $skipTo.attr('tabindex', -1).on('blur focusout', function () {
      $(this).removeAttr('tabindex');
    })

    $skipTo.focus();
  });
});
