$(function() {
  $(document).on('show.bs.modal', '.js-spreadings-modal', function(e) {
    // Reset modal to count shown in line item
    let $spreadingsCount = $(e.target).find('.js-spreadings-count');
    $spreadingsCount.val($spreadingsCount.data('originalCount'));
    $spreadingsCount.trigger("input");
  });

  $(document).on("input change", ".js-spreadings-count", function(e) {
    let newValue = $(e.target).val();
    let min = parseInt($(e.target).attr('min'));
    let max = parseInt($(e.target).attr('max'));

    // Update price
    let cost = $(e.target).closest("form").find(".js-spreadings-cost");
    cost.text((parseInt(cost.data("price")) * newValue).toLocaleString());

    let $spreadingsModal = $(e.target).parents('.modal');
    let $spreadingsIncrementButton = $spreadingsModal.find('.js-spreadings-change-increment');
    let $spreadingsDecrementButton = $spreadingsModal.find('.js-spreadings-change-decrement');

    // Enable or disable +/- buttons
    $spreadingsIncrementButton.prop('disabled', newValue == max);
    $spreadingsDecrementButton.prop('disabled', newValue == min);

    // Update copy
    $spreadingsModal.find('.js-modal-body').toggleClass('d-none', newValue == max);
    $spreadingsModal.find('.js-limit-reached-modal-body').toggleClass('d-none', newValue != max);
  });

  $(document).on("click", ".js-spreadings-change", function(e) {
    e.preventDefault();

    let $spreadingsCount = $(this).siblings(".js-spreadings-count");

    let newValue = parseInt($spreadingsCount.val()) + parseInt($(this).data("delta"));
    let min = parseInt($spreadingsCount.attr("min"));
    let max = parseInt($spreadingsCount.attr("max"));

    if (newValue >= min && newValue <= max) {
      $spreadingsCount.val(newValue);
      $spreadingsCount.trigger("input");
    }
  });

  $('.js-spreadings-modal').on('hide.bs.modal', function(e) {
    let $modal = $(e.target);

    $modal.find('.tool-tip').hide();
  });
});
