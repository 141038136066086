$(function() {
  $('.share-meet-my-tree').on('keydown', 'input.share-email-field', function(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();

      const $inputs = $('input.share-email-field');
      const nextInput = $inputs.get($inputs.index(this) + 1);

      nextInput ? nextInput.focus() : $('#message').focus();
    }
  });

  $('.add-share-email-field').on('click', function() {
    $('#share-email-field').clone().appendTo('#share-email-fields');

    const emailFields = $('.share-email-field');
    const lastEmailField = emailFields.last();
    lastEmailField.val('');
    lastEmailField.attr('placeholder', 'Email address ' + emailFields.length);
  });

  // this stamps the mmt most_recently_shared_at datetime
  // when the user clicks on the fb share button
  $('.js-mmt-share-fb').on('click', function() {
    const data = $(this).data();
    window.open(data['url'], 'sharer', 'width=500, height=500');
    globalEventSend(data['eventName'], {});
    $.ajax({
      method: 'PUT',
      url: '/meet_my_tree/' + data['urlSlug'],
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      },
    })
  });
});
